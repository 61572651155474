import axios from "app/axiosconf/axios";


class BaseAPI {
    API = "/api";

    constructor(model = null) {
        if (model)
            this.MODEL = model;
    }

    _prepare_args(args) {
        const _args = Object.values(args);
        const part = _args.filter(x => ["string", "number"].includes(typeof x))[0];
        const params = _args.filter(x => typeof x === "object")[0];

        return [part, params];
    }

    _get() {
        const [part, params] = this._prepare_args(arguments);
        return axios.get(this._make_res(part), {params});
    }

    _post() {
        const [part, params] = this._prepare_args(arguments);
        return axios.post(this._make_res(part), params);
    }

    _head() {
        const [part] = this._prepare_args(arguments);
        return axios.head(this._make_res(part));
    }

    list(params) {
        return this._get(params);
    }

    retrieve(part) {
        return this._get(part);
    }

    _make_res(part = null) {
        return `${this.get_model()}${part ? `/${part}` : ""}`;
    }

    get_model() {
        if (this.MODEL) {
            return `${this.API}/${this.MODEL}`;
        } else
            return this.API;
    }
}

class ServiceAPI extends BaseAPI {
    MODEL = "service";
}

class TeamAPI extends BaseAPI {
    MODEL = "team";
}

class PriceAPI extends BaseAPI {
    MODEL = "price";
}

class SymptomAPI extends BaseAPI {
    MODEL = "symptom";
}

class LandingSectionAPI extends BaseAPI {
    MODEL = "landingsection";
}

class LandingAPI extends BaseAPI {
    MODEL = "landing";
}

class BenefitAPI extends BaseAPI {
    MODEL = "benefit";
}

class CertificateAPI extends BaseAPI {
    MODEL = "certificate";
}

class ConfigAPI extends BaseAPI {
    MODEL = "config";

    is_updated() {
        return this._head();
    }
}

class ScheduleAPI extends BaseAPI {
    MODEL = "schedule";

    list(member, year, month) {
        return this._get(`${member}/${year}/${month}`);
    }

    list_all(member) {
        return this._get(`${member}`);
    }

    appoint(doctor, name, phone, datetime, member) {
        return this._post("appoint", {doctor, name, phone, dtm: datetime, member});
    }

    free(name, phone) {
        return this._post("free", {name, phone});
    }
}

class EducationAPI extends BaseAPI {
    MODEL = "education";
}

class PageAPI extends BaseAPI {
    MODEL = "page";
}

class AddPageAPI extends BaseAPI {
    MODEL = "page";
}

export {
    BaseAPI,
    ServiceAPI,
    TeamAPI,
    PriceAPI,
    SymptomAPI,
    LandingSectionAPI,
    BenefitAPI,
    CertificateAPI,
    LandingAPI,
    ConfigAPI,
    ScheduleAPI,
    EducationAPI,
    AddPageAPI,
    PageAPI,
};
