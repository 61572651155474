import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'app/components/link';
import {useConfig} from 'app/contexts/config';
import reachGoal from 'app/utils/reach-goal';
import formatPhone from 'app/utils/format-phone';
import {ReactComponent as LogoSVG} from 'app/assets/svg/logo/main.svg';
import {ReactComponent as PhoneSVG} from 'app/assets/svg/icons/header-phone.svg';
import {ReactComponent as BurgerSVG} from 'app/assets/svg/icons/burger.svg';
import {ReactComponent as WhatsappSVG} from 'app/assets/svg/icons/whatsapp.svg';
import Social from 'app/components/social/social';
import Lense from 'app/components/lense/lense';

import './topbar.scss';
import {AddressContactsItem, WorkTimes} from "app/components/contacts/contacts";

const Topbar = ({onShowPopupNav}) => {
    const config = useConfig();

    return (
        <div className="topbar">
            <div className="topbar__inner container">
                <Link to="/" aria-label="Главная страница" className={'mainpage-link'}>
                    <LogoSVG className="topbar__logo"/>
                </Link>

                <Social position={'header'}/>
                {/*{config.lense_show && <Lense/>}*/}

                <div className="topbar__right">
                    <ul className="topbar__info">
                        <AddressContactsItem position={'topbar'}/>

                        <WorkTimes position="topbar"/>

                        <li className="topbar__info-item  topbar__info-item--contacts">
                            {/*<a className="topbar__whatsapp"*/}
                            {/*   href={`https://wa.me/${config.secondaryPhone && config.secondaryPhone.replace(/[^\d+]/g, "")}`}*/}
                            {/*   target="_blank"*/}
                            {/*   rel="noopener noreferrer"*/}
                            {/*   aria-label="Whatsapp">*/}
                            {/*    <WhatsappSVG/>*/}
                            {/*</a>*/}
                            <PhoneSVG className="topbar__info-icon"/>
                            <div className="topbar__info-text">
                                <a
                                    className="topbar__phone analytics-mgo-number"
                                    href={`tel:${config.primaryPhone}`}
                                    onClick={() => reachGoal('phone-click', {from: 'header'})}
                                >
                                    {formatPhone(config.primaryPhone)}
                                </a>
                            </div>
                        </li>
                    </ul>

                    <button
                        className="topbar__nav-toggle"
                        type="button"
                        aria-label="Открыть меню"
                        onClick={onShowPopupNav}
                    >
                        <BurgerSVG className="topbar__icon"/>
                    </button>
                </div>
            </div>
        </div>
    );
};

Topbar.propTypes = {
    onShowPopupNav: PropTypes.func.isRequired,
};

export default Topbar;
