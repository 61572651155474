import React, {useState} from "react";
import PropTypes from 'prop-types';
import './contacts.scss';
import {ReactComponent as PlaceSVG} from "app/assets/svg/icons/header-map.svg";
import {ReactComponent as PlaceSVG1} from "app/assets/svg/icons/place.svg";
import {useConfig} from "app/contexts/config";
import {ReactComponent as ClockSVG} from 'app/assets/svg/icons/clock.svg';
import {ReactComponent as ClockSVG1} from 'app/assets/svg/icons/header-clock.svg';

function AddressFrame({children, visible}) {
    // const [vsbl, setVsbl] = useState(visible);

    return <div className={'address-shell-frame'} style={{display: (visible) ? 'block' : 'none'}}>
        <div className={'address-inner-frame'}>
            {children}
        </div>
    </div>;
}


function ContactsItem({icon: Icon, title, position = "contacts", children, type = "others"}) {

    // const data = [children[0], children[1][0]];
    const data = children;
    const [hover, _] = useState(false);

    const setHover = () => {};

    if (position === 'topbar') {
        return (
            <li className={"topbar__info-item topbar__info-item--address"}
                onMouseEnter={x => setHover(true)}
                onMouseLeave={x => setHover(false)}
                onClick={x => setHover(true)}
            >

                <PlaceSVG className="topbar__info-icon"/>
                <div className="topbar__info-text">{data}</div>
                <AddressFrame visible={hover && type == 'address'}>
                    {children}
                </AddressFrame>
            </li>);
    } else if (position === 'contacts') {
        return (
            <li className={"contacts__item"}
                onMouseEnter={x => setHover(true)}
                onMouseLeave={x => setHover(false)}
                onClick={x => setHover(true)}
            >

                <h3 className="contacts__title-wrap">
                    <Icon className="contacts__icon"/>

                    <span className="contacts__title">{title}</span>
                </h3>

                <p className="contacts__text">{data}</p>
                <AddressFrame visible={hover && type == 'address'}>
                    {children}
                </AddressFrame>
            </li>);
    } else if (position === 'footer') {
        return (
            <>
                <h3 className="footer__group-title">{title}</h3>
                <div className="footer__info">
                    {children}
                </div>
            </>
        );
    }
}

function AddressContactsItem({position = 'contacts'}) {
    const config = useConfig();

    return (
        <ContactsItem title="Наши адреса"
                      icon={PlaceSVG1}
                      position={position}
                      type={'address'}>
            <p>Екатеринбург</p>
            {
                (config.departments || []).map(x => <p>{x.address}</p>)
            }
        </ContactsItem>
    );
}


ContactsItem.propTypes = {
    icon: PropTypes.elementType.isRequired,
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};


function WorkTimes({position = "contacts"}) {
    const {worktime} = useConfig();
    const obj = worktime?worktime.map(
        x => <li className={'worktimes__item'}>{x.from_day}-{x.to_day}&nbsp;
            <b>{x.from_time}-{x.to_time}</b>
        </li>
    ):[];

    if (position === 'topbar') {
        return (
            <li className="topbar__info-item topbar__info-item--time">
                <ClockSVG1 className="topbar__info-icon"/>
                <div className="topbar__info-text">
                    <ul className="worktimes">
                        {obj}
                    </ul>
                </div>
            </li>
        )
    } else if (position === 'contacts') {
        return (
            <li className={"contacts__item"}>

                <h3 className="contacts__title-wrap">
                    <ClockSVG className="contacts__icon"/>

                    <span className="contacts__title">Время работы</span>
                </h3>

                <p className="contacts__text">
                    <ul className="worktimes">
                        {obj}
                    </ul>
                </p>
            </li>);
    } else if (position === 'footer') {
        return (
            <>
                <h3 className="footer__group-title">Время работы</h3>
                <div className="footer__info">
                    <ul className="worktimes">
                        {obj}
                    </ul>
                </div>
            </>
        );
    }

}


export {
    ContactsItem,
    AddressContactsItem,
    WorkTimes
};

