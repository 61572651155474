import enviroment from './base';

// const redirect_uri = "https://test.vertebrolog-ekb.ru";
const BASE_API = '';
const env = enviroment(BASE_API);

export default {
    ...env,
    isLocal: false,
    isProduction: true,
    isDevelopment: false,
}
