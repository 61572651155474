import FluidImg from "app/components/fluid-img";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import {Link} from "app/components/link";
import "./banner.scss";


function Banner({
                    image, title, children: text, buttonText, color, className, ...props
                }) {
    let Component;

    if (props.to) {
        Component = Link;
    } else if (props.href) {
        Component = "a";
    } else {
        Component = "button";
    }

    return (
        <Component className={classNames("banner", className, {[`banner--${color}`]: color})}
                   {...props}>
            <div className="banner__inner">
                <div className="banner__content">
                    <h3 className="banner__title">
                        {title}
                    </h3>

                    {text && (
                        <p className="banner__text">
                            {text}
                        </p>
                    )}

                    {buttonText && (
                        <div className="banner__button button">
                            {buttonText}
                        </div>
                    )}
                </div>

                <div className="banner__image">
                    <FluidImg img={image}/>
                </div>
            </div>
        </Component>
    );
}

Banner.propTypes = {
    image: PropTypes.oneOfType([
                                   PropTypes.shape({childImageSharp: PropTypes.object.isRequired}),
                                   PropTypes.shape({localFile: PropTypes.object.isRequired}),
                               ]).isRequired,
    title: PropTypes.string.isRequired,
    children: PropTypes.string,
    to: PropTypes.string,
    href: PropTypes.string,
    buttonText: PropTypes.string,
    color: PropTypes.string,
    className: PropTypes.string,
};

Banner.defaultProps = {
    children: null,
    to: null,
    href: null,
    buttonText: null,
    color: null,
    className: null,
};

export default Banner;
