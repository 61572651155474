import React from 'react';
import PropTypes from 'prop-types';
import {Link as _Link} from 'app/components/link';
import reachGoal from 'app/utils/reach-goal';
import config from 'app/config';
import Button from 'app/components/button';
import './navbar.scss';



const Link = ({children, to, className, activeClassName, ...props}) => (
    <_Link className={`${className} ${(window.location.pathname == to) && activeClassName}`}
           to={to}
           {...props}>
        {children}
    </_Link>
)

function lnk_class(link_to) {
    const prep_lnk = link_to.replaceAll('/','');

    if (prep_lnk == "")
        return 'about-us';
    else
        return prep_lnk;
}

const Navbar = ({onShowModalForm}) => (
    <nav className="navbar">
        <div className="navbar__inner container">
            <ul className="navbar__list">
                {config.navLinks.map(link => (
                    <li className={`navbar__item ${lnk_class(link.to)}`} key={link.to}>
                        <Link to={link.to}
                              className="navbar__link"
                              activeClassName="is-active">
                            {link.name}
                        </Link>
                    </li>
                ))}
            </ul>

            <Button
                className="navbar__cta"
                onClick={() => {
                    reachGoal('appointment-click', {from: 'header'});
                    onShowModalForm();
                }}
                small
            >
                записаться на прием
            </Button>
        </div>
    </nav>
);

Navbar.propTypes = {
    onShowModalForm: PropTypes.func.isRequired,
};

export default Navbar;
