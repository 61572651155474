import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import {Link} from 'app/components/link';
import reachGoal from 'app/utils/reach-goal';
import {ReactComponent as BurgerSVG} from 'app/assets/svg/icons/burger-close.svg';
// import config from 'app/config';
import './popup-nav.scss';
import {useConfig} from "app/contexts/config";

function PopupNav({onClose, onShowModalForm}) {
    const config = useConfig();

    const content = (
        <nav className="popup-nav">
            <button
                className="popup-nav__close-button" type="button" onClick={onClose}
                aria-label="Закрыть"
            >
                <BurgerSVG className="popup-nav__close-icon"/>
            </button>

            <ul className="popup-nav__main-links">
                {config.navLinks.map(link => (
                    <li key={link.to}>
                        <Link
                            to={link.to}
                            className="popup-nav__link"
                        >
                            {link.name}
                        </Link>
                    </li>
                ))}
            </ul>

            <ul className="popup-nav__other-links">
                <li>
                    <button
                        className="popup-nav__link"
                        type="button"
                        onClick={() => {
                            reachGoal('appointment-click', {from: 'mobile'});
                            onShowModalForm();
                        }}
                    >
                        Записаться на прием
                    </button>
                </li>

                {config.showCallHome &&
                <li>
                    <Link
                        className="popup-nav__link"
                        to="/pages/home-help/"
                        onClick={() => reachGoal('home-help-click', {from: 'mobile'})}
                    >
                        Вызвать врача на дом
                    </Link>
                </li>}
            </ul>
        </nav>
    );

    return ReactDOM.createPortal(content, document.getElementById('popup'));
};

PopupNav.propTypes = {
    onClose: PropTypes.func.isRequired,
    onShowModalForm: PropTypes.func.isRequired,
};

export default PopupNav;
