import FluidImg from "app/components/fluid-img";
import {Link} from "app/components/link";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import "./card.scss";


function Card({link, photo, title, children: text, simple}) {

    return <Link className={classNames("card", {"card--simple": simple})}
                 to={link}>

        <div className="card__photo">
            <FluidImg className="card__photo-inner" img={photo}/>

            {simple && (
                <h3 className="card__photo-title">
                    {title}
                </h3>
            )}
        </div>

        {!simple && (
            <div className="card__content">
                <h3 className="card__title">
                    {title}
                </h3>
                {text && (
                    <p className="card__text">
                        {text}
                    </p>
                )}
            </div>
        )}
    </Link>;
}

Card.propTypes = {
    link: PropTypes.string.isRequired,
    photo: PropTypes.oneOfType([
        PropTypes.shape({childImageSharp: PropTypes.object.isRequired}),
        PropTypes.shape({localFile: PropTypes.object.isRequired}),
    ]).isRequired,
    title: PropTypes.string.isRequired,
    children: PropTypes.string,
    simple: PropTypes.bool,
};

Card.defaultProps = {
    children: null,
    simple: false,
};

export default Card;
