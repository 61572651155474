import React, {useState} from 'react';
import PropTypes from 'prop-types';
import reachGoal from 'app/utils/reach-goal';
import Button from 'app/components/button';
import MainForm from 'app/components/main-form';
import FluidImg from 'app/components/fluid-img';
import Section from 'app/components/section';
import {ReactComponent as ShapeSVG} from 'app/assets/svg/shapes/crosses-rectangle.svg';
import './hero.scss';

function Hero({title, benefits, ctaText, image}) {
    const [isFormModalOpen, setIsFormModalOpen] = useState(false);

    function toggleModalForm() {
        setIsFormModalOpen(!isFormModalOpen);
    }

    return (
        <Section className="hero" curve color="gray">
            {isFormModalOpen && (
                <MainForm
                    title="Получить консультацию"
                    onClose={toggleModalForm}
                    onSubmit={() => reachGoal('main-hero-cta-submit')}
                />
            )}

            <div className="hero__inner container">
                <div className="hero__content">
                    <div className="hero__content-inner">
                        <div className="hero__header" dangerouslySetInnerHTML={{__html: title}}/>

                        <ul className="hero__benefits">
                            {benefits && benefits.map(({title, text}) => (
                                <li className="hero__benefits-item" key={title}>
                                    <h1 className="hero__benefits-title">
                                        {title}
                                    </h1>

                                    {text && (
                                        <div
                                            className="hero__benefits-text"
                                            dangerouslySetInnerHTML={{__html: text}}
                                        />
                                    )}
                                </li>
                            ))}
                        </ul>

                        <Button
                            className="hero__cta"
                            onClick={() => {
                                reachGoal('main-hero-cta-click');
                                toggleModalForm();
                            }}
                            big
                            accent
                        >
                            {ctaText || "ЗАПИСАТЬСЯ НА ПРИЁМ"}
                        </Button>
                    </div>
                </div>

                <div className="hero__figure a-zoom-out a-dr-3 a-dl-1 a-e-in-out-spine">
                    <FluidImg className="hero__image" img={image}/>
                    <ShapeSVG className="hero__shape" aria-hidden/>
                </div>
            </div>
        </Section>
    );
}

const benefitShape = PropTypes.shape({
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
});

Hero.propTypes = {
    title: PropTypes.string.isRequired,
    benefits: PropTypes.arrayOf(benefitShape).isRequired,
    image: PropTypes.oneOfType([
        PropTypes.shape({childImageSharp: PropTypes.object.isRequired}),
        PropTypes.shape({localFile: PropTypes.object.isRequired}),
    ]).isRequired,
    ctaText: PropTypes.elementType,
};

Hero.defaultProps = {
    ctaText: 'Записаться на прием',
};

export default Hero;
