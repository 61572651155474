export default function formatPhone(phone = '') {
    if (!phone) return '';

    const match = phone
        .replace(/\D/g, '')
        .match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/);

    return match
        ? `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}-${match[5]}`
        : phone;
}
