import {useEffect, useState} from "react";

const ALTER_MODE = "alter-mode";

const getAlterMode = () => JSON.parse(localStorage.getItem(ALTER_MODE)) || false;

export const useTheme = () => {
    const [alterMode, setAlterMode] = useState(getAlterMode);

    useEffect(() => {
        const initialValue = getAlterMode();
        if (initialValue !== alterMode) {
            localStorage.setItem(ALTER_MODE, alterMode);
            window.location.reload();
        }
    }, [alterMode]);

    return [alterMode, setAlterMode];
};
