import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import reachGoal from 'app/utils/reach-goal';
import Section from 'app/components/section';
import Intro from 'app/components/intro';
import MainForm from 'app/components/main-form';
import Banner from 'app/components/banner';
import {ReactComponent as IntroSVG} from 'app/assets/svg/intro/diagnostics.svg';
import './diagnostics.scss';
// import '../diags/diags.scss';

import {ServiceAPI} from "app/api/base";
import Spinner from "app/components/dna";

const Diagnostics = ({hasIcon, primaryOnly, ...props}) => {
    const [isFormModalOpen, setIsFormModalOpen] = useState(false);
    const [services, setServices] = useState([]);

    useEffect(() => {
        const filters = {category: 'diagnostics'}
        if (primaryOnly) filters.is_primary = 1;
        new ServiceAPI().list(filters).then(r => {
            setServices(r.data);
        });
    }, []);

    function toggleModalForm() {
        setIsFormModalOpen(!isFormModalOpen);
    }

    return (
        <Section {...props}>
            {isFormModalOpen && (
                <MainForm
                    title="Записаться на прием"
                    onSubmit={() => reachGoal('appointment-submit', {from: 'banners'})}
                    onClose={toggleModalForm}
                />
            )}

            <Intro title="Новые методы диагностики"
                   text={"Мы постоянно расширяем наши возможности в диагностике, чтобы"
                       + " Вы могли пройти полное обследование в одном месте."}
                   icon={hasIcon ? IntroSVG : null}/>

            <ul className="diagnostics__list container">
                <Spinner spin={!services.length}>
                {/*<Spinner spin={true}>*/}
                    {services.map(srv => (
                        <li className="diagnostics__item" data-sal="slide-down">
                            <Banner image={srv.img_for_main || srv.img}
                                    title={srv.name}
                                    buttonText="Подробнее"
                                    onClick={() => window.location.href = srv?.landing?.link
                                        || `/diagnostic/${srv.seo_name || srv.id}/`}
                                    color={srv.color}>
                                {srv.intro}
                            </Banner>
                        </li>
                    ))}
                </Spinner>
            </ul>
        </Section>
    );
};

Diagnostics.defaultProps = {
    hasIcon: true,
    primaryOnly: false
};

Diagnostics.propTypes = {
    hasIcon: PropTypes.bool,
    primaryOnly: PropTypes.bool
};

export default Diagnostics;
