import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { BODY_PARTS } from 'app/constants';

const TextNav = ({ isActiveBodyPart, onSelectBodyPart }) => (
  <div className="body-nav__section" data-sal="slide-right">
    <h3 className="body-nav__title">
      <span className="body-nav__number">1</span>
      Выберите часть тела:
    </h3>

    <ul className="body-nav__list">
      {BODY_PARTS.map(bodyPart => (
        <li className="body-nav__item" key={bodyPart.id}>
          <button
            className={
              classNames(
                'body-nav__area-button',
                { 'is-active': isActiveBodyPart(bodyPart) },
              )
            }
            type="button"
            onClick={() => onSelectBodyPart(bodyPart)}
          >
            {bodyPart.name}
          </button>
        </li>
      ))}
    </ul>
  </div>
);

TextNav.propTypes = {
  isActiveBodyPart: PropTypes.func.isRequired,
  onSelectBodyPart: PropTypes.func.isRequired,
};

export default TextNav;
