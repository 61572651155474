import React from 'react';
import {ReactComponent as CircleSVG} from 'app/assets/svg/icons/circle.svg';
import './spinner.scss';

const Spinner = () => (
  <div className="spinner a-zoom-in a-dr-4 a-e-in-out-spine">
    <CircleSVG className="spinner__dot" />
    <CircleSVG className="spinner__dot" />
    <CircleSVG className="spinner__dot" />
  </div>
);

export default Spinner;
