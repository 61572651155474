import {ServiceAPI} from "app/api/base";
import Card from "app/components/card";
import Spinner from "app/components/dna";
import Intro from "app/components/intro";
import Layout from "app/components/layout";
import Section from "app/components/section";
import {useSeo} from "app/contexts/seo";
import Benefits from "app/sections/benefits";
import Diagnostics from "app/sections/diagnostics";
import Methods from "app/sections/methods";
import React from "react";


function ServicesPage() {
    const [sections, setSections] = React.useState([]);
    const {setSeoName} = useSeo();


    React.useEffect(() => {
        setSeoName('services');
        new ServiceAPI().list({
            category: [
                'massage',
                'manipul',
                'procedures',
                'consults',
                'injections',
                'children',
                'spec'
            ],
            is_primary: 0,
            as_tree: 1
        }).then(r => {
            setSections(r?.data);
        })
    }, []);

    return (
        <Layout>
            <Methods hasIcon={false}/>
            <Diagnostics hasIcon={false} merged/>

            <Spinner spin={!sections?.length}>
                <Section color="gray" curve>
                    {sections.map(({category: title, services: items}) => {
                        return <React.Fragment key={title}>
                            <Intro title={title}/>

                            <ul className="card-list container">
                                {items.map(({id, img, name, intro, seo_name, landing}) => (
                                    <li className="card-list__item" key={id}>
                                        <Card link={landing?.link || `/services/${seo_name || id}`}
                                              photo={img}
                                              title={name}>
                                            {intro}
                                        </Card>
                                    </li>
                                ))}
                            </ul>
                        </React.Fragment>
                    })}
                </Section>
            </Spinner>

            <Benefits/>
        </Layout>
    );
}

export default ServicesPage;
