import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
// import { useStaticQuery, graphql } from 'gatsby';
import Section from 'app/components/section';
import classNames from 'classnames';
import {useConfig} from 'app/contexts/config';
import formatPhone from 'app/utils/format-phone';
import {ReactComponent as IntroSVG} from 'app/assets/svg/intro/faq.svg';
import {ReactComponent as ArrowDownSVG} from 'app/assets/svg/icons/arrow-down.svg';
import Intro from 'app/components/intro';
import './faq.scss';
import {BaseAPI} from "app/api/base";

const getDefaultQuestion = config => (
    {
        question: 'У меня есть другой вопрос. Как я могу его задать?',
        answer: `Задайте нам свой вопрос по телефону
    <a class="analytics-mgo-number" href="tel:${config.primaryPhone}">${formatPhone(config.primaryPhone)}</a> или отправьте его на
    <a class="link" href="mailto:${config.email}">${config.email}</a>.
    Мы обязательно на него ответим.`,
    }
);

function FAQItem({question, defaultIsActive}) {
    const [isActive, setIsActive] = useState(defaultIsActive);

    return (
        <li className={classNames('faq__item', {'is-active': isActive})}>
            <h3>
                <button
                    className="faq__button"
                    type="button"
                    onClick={() => setIsActive(!isActive)}
                >
          <span className="faq__question content__title">
            {question.question}
              <ArrowDownSVG className="faq__arrow"/>
          </span>
                </button>
            </h3>

            <div className="faq__answer">
                <p
                    className="faq__answer-inner content__text"
                    dangerouslySetInnerHTML={{__html: question.answer}}
                />
            </div>
        </li>
    );
}

FAQItem.propTypes = {
    question: PropTypes.shape({
        question: PropTypes.string.isRequired,
        answer: PropTypes.string.isRequired,
    }).isRequired,
    defaultIsActive: PropTypes.bool,
};

FAQItem.defaultProps = {
    defaultIsActive: false,
};

const FAQ = ({hasIcon, ...props}) => {
    const config = useConfig();
    const [questions, setQuestions] = useState([]);

    useEffect(() => {
        new BaseAPI('qa').list().then(res => {
            setQuestions(res.data);
        })
    }, []);

    return (
        <Section {...props}>
            <Intro
                title="Частые вопросы и ответы"
                icon={hasIcon ? IntroSVG : null}
            />

            <ul className="faq__list container">
                {questions.map(qa => (
                    <FAQItem
                        question={qa}
                        defaultIsActive={false}
                        // key={qa.id}
                    />
                ))}
                <FAQItem question={getDefaultQuestion(config)}/>
            </ul>
        </Section>
    );
}

FAQ.defaultProps = {
    hasIcon: true,
};

FAQ.propTypes = {
    hasIcon: PropTypes.bool,
};

export default FAQ;
