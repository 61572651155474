import {ReactComponent as VkontakteSVG} from 'app/assets/svg/icons/vk.com.svg';
// import {ReactComponent as FacebookSVG} from 'app/assets/svg/icons/facebook.svg';
// import {ReactComponent as InstagramSVG} from 'app/assets/svg/icons/instagram.svg';
import {ReactComponent as WhatsappSVG} from 'app/assets/svg/icons/whatsapp.svg';
import {ReactComponent as OkSVG} from 'app/assets/svg/icons/ok.svg';
import {ReactComponent as TelegramSVG} from 'app/assets/svg/icons/tg.svg';
import React from 'react';
import PropTypes from 'prop-types';
import './social.scss';
import {useConfig} from "app/contexts/config";

const Social = ({position}) => {
    const config = useConfig();

    return <div className={`social ${position}__social`}>
        <ul className={`social-list ${position}__social-list`}>
            <li>
                <a className="whatsapp"
                   href={`https://wa.me/${config.secondaryPhone && config.secondaryPhone.replace(/[^\d+]/g, "")}`}
                   target="_blank"
                   rel="noopener noreferrer"
                   aria-label="Вконтакте">
                    <WhatsappSVG/>
                </a>
            </li>

            <li>
                <a className="vk"
                   href="https://vk.com/vertebrocentr"
                   target="_blank"
                   rel="noopener noreferrer"
                   aria-label="Вконтакте">
                    <VkontakteSVG/>
                </a>
            </li>
        </ul>
        <ul className={`social-list ${position}__social-list`}>
            <li>
                <a className="ok"
                   href="https://ok.ru/group/70000004070479"
                   target="_blank"
                   rel="noopener noreferrer"
                   aria-label="Одноклассники.ru">
                    <OkSVG/>
                </a>
            </li>

            <li>
                <a className="tg"
                   href="https://t.me/vertebroclinic"
                   target="_blank"
                   rel="noopener noreferrer"
                   aria-label="Telegram">
                    <TelegramSVG/>
                </a>
            </li>
        </ul>
    </div>
};

Social.propTypes = {
    position: PropTypes.string.isRequired,
};

export default Social;
