import {Link as _Link} from 'react-router-dom';

function check(to) {
    return (typeof to === 'string')?to:"";
}

const Link = ({children, to, className, ...props}) => (
    <_Link to={(check(to).slice(-1)=='/')?to:`${to}/`}
                  className={className}
                  {...props}>
        {children}
    </_Link>)

export default Link;
export {
    Link
}
