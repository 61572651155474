import StarMeter from "app/components/star-meter";
import React from "react";

import "./review-outer.scss";


export function ReviewOuterItem({stars, link, img}) {

    return (
        <div className="review">
            <blockquote className="review__content">
                <a className="wrapper-image"
                   href={link}
                   target="_blank">
                    <img src={img} alt={img}/>
                </a>
            </blockquote>
            <div className="review__footer">
                <h2>{stars}/5</h2><StarMeter stars={stars}/>
            </div>
        </div>
    );
}



// export function ReviewOuter({}) {
//
// }
