import {Form as FormikForm, Formik} from "formik";
import PropTypes from "prop-types";
import React from "react";
import {Link} from "react-router-dom";
import Button from "../button";
import Field from "../field";


function validate({name, phone}) {
    const errors = {};

    if (!name) {
        errors.name = 'Напишите ваше имя';
    }

    if (!phone) {
        errors.phone = 'Напишите ваш номер телефона';
    }

    return errors;
}

const Form = ({title, onSubmit}) => (

    <>
        <h2 className="main-form__title">
            {title}
        </h2>

        <Formik initialValues={{name: '', phone: ''}}
                validate={validate}
                onSubmit={onSubmit}>

            {({isSubmitting}) => (
                <FormikForm>
                    <div className="main-form__section">
                        <Field className="main-form__input"
                               name="name"
                               type="text"
                               placeholder="Напишите ваше имя"
                               labelText="Ваше имя"
                               labelClassName="main-form__label"
                               errorClassName="main-form__error"/>
                    </div>

                    <div className="main-form__section">
                        <Field className="main-form__input"
                               name="phone"
                               type="tel"
                               placeholder="Напишите ваш номер телефона"
                               labelText="Контактный телефон"
                               labelClassName="main-form__label"
                               errorClassName="main-form__error"/>
                    </div>

                    <div className="main-form__section">
                        <Button
                            className="main-form__button"
                            type="submit"
                            processing={isSubmitting}
                            full
                        >
                            {title}
                        </Button>

                        <p className="main-form__note">
                            Нажимая кнопку, Вы даете согласие на
                            {' '}
                            <Link className="link" to="/pages/privacy-policy/" target="_blank">
                                обработку персональных данных
                            </Link>
                            .
                        </p>
                    </div>
                </FormikForm>
            )}
        </Formik>
    </>
);

Form.propTypes = {
    title: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default Form;
