import gis from "app/assets/images/banners/2gis.png";
import google from "app/assets/images/banners/google.png";

import prodoctorov from "app/assets/images/banners/prodoctorov.png";
import yandex from "app/assets/images/banners/yandex.png";
import zoon from "app/assets/images/banners/zoon.png";
import {ReactComponent as IntroSVG} from "app/assets/svg/intro/reviews.svg";
import Intro from "app/components/intro/intro";
import {ReviewOuterItem} from "app/components/review-outer/review-outer";
import Section from "app/components/section";
import Slider from "app/components/slider";
import {useBreakpoints} from "app/contexts/breakpoints";


const OUTER_MARKS = [
    {
        img: prodoctorov,
        href: "https://prodoctorov.ru/ekaterinburg/set/4401-vertebrocentr/",
        stars: 5,
    },
    {
        img: google,
        href: "https://www.google.com/maps/place/%D0%92%D0%B5%D1%80%D1%82%D0%B5%D0%B1%D1%80%D0%BE%D1%86%D0%B5%D0%BD%D1%82%D1%80/data=!4m7!3m6!1s0x43c16dda8a2d8521:0xc60d72bc08df78f9!8m2!3d56.8890796!4d60.6024618!16s%2Fg%2F11ffw7mfcn!19sChIJIYUtitptwUMR-XjfCLxyDcY?authuser=0&hl=ru&rclk=1",
        stars: 5,
    },
    {
        img: gis,
        href: "https://2gis.ru/ekaterinburg/firm/70000001061156503/60.602475%2C56.889076",
        stars: 5,
    },
    {
        img: zoon,
        href: "https://zoon.ru/ekb/medical/klinika_nevrologii_i_manualnoj_terapii_vertebrotsentr_na_kirovgradskoj_ulitse/",
        stars: 4,
    },
    {
        img: yandex,
        href: "https://yandex.ru/maps/org/vertebrotsentr/10367299966/?ll=60.599948%2C56.889782&z=13.88",
        stars: 5,
    },
];


export function ReviewOuterSection({...props}) {
    const breakpoints = useBreakpoints();

    return (
        <Section color="warm" curve>
            <Intro
                title="Вертеброцентр на популярных сервисах"
                icon={IntroSVG}
            />
            <Slider itemsPerStep={breakpoints.xxl ? 3 : (breakpoints.l ? 2 : 1)}>
                {OUTER_MARKS.map(x => (
                    <Slider.Item>
                        <ReviewOuterItem stars={x.stars}
                                         link={x.href}
                                         img={x.img}/>
                    </Slider.Item>
                ))}
            </Slider>
        </Section>
    );

}
