import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'app/components/link';
import {ReactComponent as ArrowRight} from 'app/assets/svg/icons/arrow-right.svg';

const Symptoms = ({ symptoms }) => (
  <div className="body-nav__section" data-sal="slide-left">
    <h3 className="body-nav__title">
      <span className="body-nav__number">2</span>
      Выберите симптом:
    </h3>

    <ul className="body-nav__list">
      {symptoms.map(symptom => (
        <li className="body-nav__item a-slide-left a-df" key={symptom.name}>
          <Link
            className="body-nav__link"
            to={`/symptoms/${symptom.seo_name || symptom.id}`}
          >
            {symptom.name}

            <ArrowRight className="body-nav__link-arrow" />
          </Link>
        </li>
      ))}
    </ul>
  </div>
);

const symptomType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
});

Symptoms.propTypes = {
  symptoms: PropTypes.arrayOf(symptomType).isRequired,
};

export default Symptoms;
