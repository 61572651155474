import React from 'react';
import PropTypes from 'prop-types';
import { useConfig } from 'app/contexts/config';
import formatPhone from 'app/utils/format-phone';
import {ReactComponent as FailSVG} from 'app/assets/svg/icons/fail.svg';
import Button from 'app/components/button';

export function Fail({ onTryAgain }) {
  const {primaryPhone} = useConfig();

  return (
    <div className="main-form__result a-fade a-dr-2">
      <FailSVG className="main-form__result-icon main-form__result-icon--fail a-slide-up a-dl-1 a-dr-3 a-e-out-expo" />

      <h2 className="main-form__result-title">
        Неудачная попытка
      </h2>

      <p className="main-form__result-text">
        Не удалось отправить форму из-за проблем со связью.
        Попробуйте снова или позвоните по телефону:
        <br />
        <b>
          <a className="analytics-mgo-number" href={`tel:${primaryPhone}`}>
            {formatPhone(primaryPhone)}
          </a>
        </b>
      </p>

      <Button
        className="main-form__result-button"
        onClick={onTryAgain}
      >
        попробовать снова
      </Button>
    </div>
  );
}

Fail.propTypes = {
  onTryAgain: PropTypes.func.isRequired,
};


export function Busy({ onTryAgain }) {
  const {primaryPhone} = useConfig();

  return (
    <div className="main-form__result a-fade a-dr-2">
      <FailSVG className="main-form__result-icon main-form__result-icon--fail a-slide-up a-dl-1 a-dr-3 a-e-out-expo" />

      <h2 className="main-form__result-title">
        Ой!...
      </h2>

      <p className="main-form__result-text">
        Извините, кто-то уже успел записаться на это время чуть раньше вас.
          Пожалуйста, выберите другое время
        <br />
        <b>
          <a className="analytics-mgo-number" href={`tel:${primaryPhone}`}>
            {formatPhone(primaryPhone)}
          </a>
        </b>
      </p>

      <Button
        className="main-form__result-button"
        onClick={onTryAgain}
      >
        попробовать снова
      </Button>
    </div>
  );
}

Busy.propTypes = {
  onTryAgain: PropTypes.func.isRequired,
};

