import React, {useState} from 'react';
// import { useStaticQuery, graphql } from 'gatsby';
import {useBreakpoints} from "app/contexts/breakpoints";
import Section from 'app/components/section';
import {ReactComponent as IntroSVG} from 'app/assets/svg/intro/reviews.svg';
import Slider from 'app/components/slider';
import Review from 'app/components/review';
import Modal from 'app/components/modal';
import Intro from 'app/components/intro';
import {BaseAPI} from "app/api/base";

const Reviews = (props) => {
    const breakpoints = useBreakpoints();
    const [reviewForModal, setReviewForModal] = useState(null);
    const [reviews, setReviews] = useState([]);

    React.useEffect(() => {
        new BaseAPI('review').list().then(r => {
            setReviews(r.data)
        })
    }, [])

    return (
        <Section {...props}>
            {reviewForModal && (
                <Modal onClose={() => setReviewForModal(null)}>
                    <Review review={reviewForModal}/>
                </Modal>
            )}

            <Intro
                title="Отзывы наших пациентов"
                text="Мы не только устраняем болевой синдром, но и добиваемся максимального восстановления нарушенных функций."
                icon={IntroSVG}
            />

            <Slider itemsPerStep={breakpoints.l ? 2 : 1}>
                {reviews.map(r => (
                    <Slider.Item>
                        <Review
                            review={r}
                            isTruncated
                            showFullReview={() => setReviewForModal(r)}
                        />
                    </Slider.Item>
                ))}
            </Slider>
        </Section>
    );
};

export default Reviews;
