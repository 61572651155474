import axios from "app/axiosconf/axios";
import env from "environment";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {Dna} from "./dna";


function FluidImg({img, alt, style, ...props}) {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    let img_file = img?.localFile?.childImageSharp?.fluid || img?.childImageSharp?.fluid || img,
        full_img_path = null;

    useEffect(() => {
        setLoading(true);
        if (img_file) {

            if (env.isLocal && !img_file.includes("http://localhost") && !img_file.includes("data:")) {
                if (img_file.includes('static'))
                    full_img_path = `http://localhost:3000${img_file}`;
                else
                    full_img_path = `http://localhost:8000${img_file}`;
            } else {
                full_img_path = img_file;
            }

            if (!full_img_path.includes("data:")) {

                axios.get(full_img_path, {responseType: 'blob'})
                    .then(resp => {
                        let reader = new window.FileReader();
                        reader.readAsDataURL(resp.data);
                        reader.onload = function () {
                            setData(reader.result);
                            setLoading(false)
                        }
                    })
                    .catch(err => {
                    });
            } else {
                setData(full_img_path);
            }
        }
        return () => {
            setData(null);
            setLoading(true);
        };
    }, [img]);

    if (!img)
        return null;

    return <>
        {loading ?
            <Dna display={loading}/> :
            <img alt={alt}
                 src={data}
                 {...props}/>}
    </>

}

FluidImg.propTypes = {
    img: PropTypes.oneOfType([
        PropTypes.shape({childImageSharp: PropTypes.object.isRequired}),
        PropTypes.shape({localFile: PropTypes.object.isRequired}),
        PropTypes.string
    ]).isRequired,
};

export default FluidImg;
