import { lazy, Suspense } from "react";
import { useTheme } from "./use-theme";

const AlterTheme = lazy(() => import("./alter-theme"));
const OrigTheme = lazy(() => import("./orig-theme"));

export function ThemeProvider({ children }) {
  const [alterMode] = useTheme();

  return (
    <>
      <Suspense fallback={<span />}>
        {alterMode ? <AlterTheme /> : <OrigTheme />}
      </Suspense>
      {children}
    </>
  );
}
