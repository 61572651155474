import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {ReactComponent as PointSVG} from 'app/assets/svg/icons/timeline-point-marked.svg';
import './timeline.scss';

const Timeline = ({ items, isBright }) => (
  <ul className={classNames('timeline', { 'timeline--bright': isBright })}>
    {items.map((item, index) => (
      <li className="timeline__item" key={index}>
        <div className="timeline__point">
          <PointSVG />
        </div>

        <p className="timeline__date">
          {item.year}
        </p>

        {item.title && (
          <h3 className="timeline__title">
            {item.title}
          </h3>
        )}

        {item.descr && (
          <p className="timeline__text">
            {item.descr}
          </p>
        )}
      </li>
    ))}
  </ul>
);

const itemType = PropTypes.shape({
  year: PropTypes.string.isRequired,
  title: PropTypes.string,
  text: PropTypes.string,
});

Timeline.defaultProps = {
  isBright: false,
};

Timeline.propTypes = {
  items: PropTypes.arrayOf(itemType).isRequired,
  isBright: PropTypes.bool,
};

export default Timeline;
