import {useSeo} from "app/contexts/seo";
import React, {useState} from 'react';
import reachGoal from 'app/utils/reach-goal';
import FluidImg from 'app/components/fluid-img';
import MainForm from 'app/components/main-form';
import VideoModal from 'app/components/video-modal';
import {ReactComponent as CircleClockSVG} from 'app/assets/svg/icons/circle-clock.svg';
import {ReactComponent as CircleStarSVG} from 'app/assets/svg/icons/circle-star.svg';
import {ReactComponent as PlaySVG} from 'app/assets/svg/icons/play.svg';
import Button from 'app/components/button';
import Section from 'app/components/section';
import {useConfig} from "app/contexts/config";
import './main-hero.scss';

function MainHero(props) {
    const [isFormModalOpen, setIsFormModalOpen] = useState(false);
    const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
    const config = useConfig();
    const {titles} = useSeo();

    function toggleModalForm() {
        setIsFormModalOpen(!isFormModalOpen);
    }

    function toggleModalVideo() {
        setIsVideoModalOpen(!isVideoModalOpen);
    }

    return (<>
        <Section className="main-hero" {...props}>
            {isVideoModalOpen && <VideoModal youtubeID="NXD6kfpfJ2c" title="Видео о клинике" onClose={toggleModalVideo}/>}
            {isFormModalOpen && (
                <MainForm
                    title="Получить консультацию"
                    onClose={toggleModalForm}
                    onSubmit={() => reachGoal('main-hero-cta-submit')}
                />
            )}

            <div className="main-hero__inner container">
                <div className="main-hero__section main-hero__section--left">
                    <h1 className="main-hero__header">
                        {titles.meta_title}
                    </h1>

                    <ul className="main-hero__benefits">
                        <li className="main-hero__benefits-item">
                            <CircleClockSVG className="main-hero__benefits-icon"/>

                            <div>
                                <h2 className="main-hero__benefits-title">Быстрое снятие боли</h2>

                                {config.quick_pain &&
                                    <p className="main-hero__benefits-text"
                                       dangerouslySetInnerHTML={{__html: config.quick_pain}}/>}
                            </div>
                        </li>

                        <li className="main-hero__benefits-item">
                            <CircleStarSVG className="main-hero__benefits-icon"/>

                            <div>
                                <h2 className="main-hero__benefits-title">
                                    Опытные специалисты
                                </h2>

                                {config.skilled_specs &&
                                    <p className="main-hero__benefits-text"
                                       dangerouslySetInnerHTML={{__html: config.skilled_specs}}/>}
                            </div>
                        </li>
                    </ul>

                    <Button
                        onClick={() => {
                            reachGoal('main-hero-cta-click');
                            toggleModalForm();
                        }}
                        big
                        accent
                    >
                        Получить консультацию
                    </Button>
                </div>

                <div className="main-hero__section main-hero__section--right a-zoom-out a-dr-3 a-dl-1 a-e-in-out-spine">
                    <button className="main-hero__video"
                            type="button"
                            aria-label="Смотреть видео"
                            onClick={toggleModalVideo}>
                        <FluidImg img={config.pictureForMainPageVideo} alt="Фрагмент видео"/>

                        <span className="main-hero__video-play">
                          <PlaySVG className="main-hero__video-icon"/>
                        </span>
                    </button>

                    <h2 className="main-hero__video-title">
                        Посмотрите видео и узнайте о нас за 3 минуты
                    </h2>

                    <blockquote className="main-hero__video-text">
            <span className="u-text-italic">
              “Нас рекомендуют своим родным, потому что помогает.”
            </span>
                        <br/>
                        — Евгений Борисович, Главный врач.
                    </blockquote>
                </div>
            </div>
            <br/>
        </Section>
            {/*<Section>*/}
            {/*    <div //className="main-hero__section main-hero__section--right a-zoom-out a-dr-3 a-dl-1 a-e-in-out-spine"*/}
            {/*         style={{float: "right"}}>*/}
            {/*        <a href={"https://yandex.ru/maps/-/CCURV4HZtA"}>*/}
            {/*            <FluidImg img={"http://localhost:8000/static/media/vertebro-tour.png"}/>*/}
            {/*        </a>*/}
            {/*    </div>*/}
            {/*</Section>*/}
        {/*    <Section className={'main-hero'}>*/}
        {/*    <div //className="main-hero__section main-hero__section--right a-zoom-out a-dr-3 a-dl-1 a-e-in-out-spine"*/}
        {/*         style={{float: "right", width: "50%", height: "400px"}}*/}
        {/*         dangerouslySetInnerHTML={{__html: yandex_tour}}*/}
        {/*    >*/}
        {/*        /!*<div style={{"position": "relative", overflow: "hidden"}}>*!/*/}
        {/*        /!*    <a href="https://yandex.ru/maps/org/vertebrotsentr/19385814515/?utm_medium=mapframe&utm_source=maps"*!/*/}
        {/*        /!*       style={{color: "#eee", "font-size": "12px", "position": "absolute", 'top': "0px"}}>Вертеброцентр</a>*!/*/}
        {/*        /!*    <a href="https://yandex.ru/maps/54/yekaterinburg/category/medical_center_clinic/184106108/?utm_medium=mapframe&utm_source=maps"*!/*/}
        {/*        /!*       style={{"color": "#eee", "font-size":"12px", "position":"absolute", "top":"14px"}}>Медцентр, клиника в Екатеринбурге</a>*!/*/}
        {/*        /!*    <a href="https://yandex.ru/maps/54/yekaterinburg/category/massage_salon/184106074/?utm_medium=mapframe&utm_source=maps"*!/*/}
        {/*        /!*       style={{"color":"#eee","font-size":"12px","position":"absolute","top":"28px"}}>Массажный салон в Екатеринбурге</a>*!/*/}
        {/*        /!*    <iframe*!/*/}
        {/*        /!*        src={"https://yandex.ru/map-widget/v1/?ll=60.604262%2C56.895512&mode=search&oid=19385814515&ol=biz&panorama%5Bdirection%5D=" +*!/*/}
        {/*        /!*        "281.627616%2C-4.584814&panorama%5Bfull%5D=true&panorama%5Bpoint%5D=60.602686%2C56.889126&panorama%5Bspan%5D=117.097550%2C60." +*!/*/}
        {/*        /!*        "000000&sll=60.601479%2C56.889308&sspn=0.072527%2C0.034389&text=%D0%92%D0%B5%D1%80%D1%82%D0%B5%D0%B1%D1%80%D0%BE%D1%86%D0%B5%" +*!/*/}
        {/*        /!*        "D0%BD%D1%82%D1%80%09%D0%95%D0%BA%D0%B0%D1%82%D0%B5%D1%80%D0%B8%D0%BD%D0%B1%D1%83%D1%80%D0%B3%2C%20%D0%9A%D0%B8%D1%80%D0%BE%D" +*!/*/}
        {/*        /!*        "0%B2%D0%B3%D1%80%D0%B0%D0%B4%D1%81%D0%BA%D0%B0%D1%8F%2C%2012&utm_source=share&z=15"}*!/*/}
        {/*        /!*        width="560" height="400" frameBorder="1" allowFullScreen="true" style={{"position": "relative"}}/>*!/*/}
        {/*        /!*</div>*!/*/}
        {/*    </div>*/}

        {/*</Section>*/}
        </>
    );
}

export default MainHero;
