import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useBreakpoints} from 'app/contexts/breakpoints';
import Modal from '../modal';
import Aside from './aside';
import Success from './success';
import {Fail} from './fail';
import Form from './form';
import './main-form.scss';
import {ScheduleAPI} from "app/api/base";

const MainForm = ({onClose, onSubmit, title}) => {
    const [isSuccess, setIsSuccess] = useState(false);
    const [isFail, setIsFail] = useState(false);
    const breakpoints = useBreakpoints();

    function handleTryAgain() {
        setIsSuccess(false);
        setIsFail(false);
    }

    function handleSubmit(values, {setSubmitting}) {
        (typeof onSubmit === 'function') && onSubmit();

        new ScheduleAPI().free(
            values?.name,
            values?.phone,
        ).then(r => {
            if (r.data === 'DONE')
                setIsSuccess(true);
            else
                setIsFail(true);
        }).catch(() => setIsFail(true))
            .finally(() => setSubmitting(false));
    }

    return (
        <Modal onClose={onClose}>
            <div className="main-form">
                {breakpoints.m && <Aside/>}

                <div className="main-form__content">
                    {isSuccess && <Success onClose={onClose}/>}

                    {isFail && <Fail onTryAgain={handleTryAgain}/>}

                    <Form title={title} onSubmit={handleSubmit}/>
                </div>
            </div>
        </Modal>
    );
};

MainForm.defaultProps = {
    onSubmit: null,
};

MainForm.propTypes = {
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    onSubmit: PropTypes.func,
};

export default MainForm;
