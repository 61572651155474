import React, {useEffect} from 'react';
import Helmet from 'react-helmet';
import FAQ from 'app/sections/faq';
import Benefits from 'app/sections/benefits';
import Layout from 'app/components/layout';
import Price from 'app/components/price';
import Section from 'app/components/section';
import Intro from 'app/components/intro';
import {PriceAPI} from "app/api/base";
import Spinner from "app/components/dna";

function PricingPage() {
    const [sections, setSections] = React.useState([]);

    useEffect(() => {
        new PriceAPI().list().then(res => {
            const pl = res.data;
            const sections = pl.reduce((grp, p) => {
                const type_rec = {id: p.type.id, title: p.type.name};
                grp[type_rec.id] = type_rec;
                return grp;
            }, {});
            for (let i in sections) {
                const t = sections[i];
                t.items = pl.filter(p => p.type.id === t.id).sort((a, b) => ((a.order || 999) < (b.order || 999)) ? -1 : 1)
            }
            setSections(Object.values(sections));
        });
    }, []);


    return (
        <Layout>
            <Helmet>
                <title>Цены на наши услуги</title>
            </Helmet>
            <Section color="gray" curve>
                <Intro title="Цены на наши услуги"/>
                <Spinner spin={!sections.length}>
                    <div className="container">
                        {sections.map(section => (
                            <Price
                                title={section.title}
                                items={section.items}
                                key={section.title}
                            />
                        ))}
                    </div>
                </Spinner>
            </Section>

            <Benefits/>
            <FAQ hasIcon={false}/>
        </Layout>
    );
}

export default PricingPage;
