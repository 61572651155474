import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'app/components/link';
import Helmet from 'react-helmet';
import Section from 'app/components/section';
import Layout from 'app/components/layout';
import {BaseAPI} from "app/api/base";
import {useParams} from "react-router";

function PageTemplate() {
    const [links, setLinks] = useState([]);
    const [page, setPage] = useState({});
    const {id} = useParams();

    useEffect(() => {
        new BaseAPI('page').retrieve(id).then(r => {
            setPage(r.data);
        });
    }, []);

    return (
        <Layout>
            <Helmet>
                <title>{page?.title}</title>
                <meta name="description" content={page?.description}/>
                <meta name="keywords" content={page?.keywords}/>
            </Helmet>

            <Section>
                <div className="container container--narrow">
                    <ul className="tabs">
                        {links.map(link => (
                            <li className="tabs__item" key={link.id}>
                                <Link
                                    to={`/pages/${link.seo_name || link.id}/`}
                                    className="tabs__button"
                                    activeClassName="is-active"
                                >
                                    {link.title}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>

                <div
                    className="content container container--narrow"
                    dangerouslySetInnerHTML={{__html: page.content}}
                />
            </Section>
        </Layout>
    );
}

export default PageTemplate;
