import React from 'react';
import reachGoal from 'app/utils/reach-goal';
import {useConfig} from 'app/contexts/config';
import formatPhone from 'app/utils/format-phone';
import {ReactComponent as WhatsappSVG} from 'app/assets/svg/icons/whatsapp.svg';
import './mobilebar.scss';
import Social from 'app/components/social/social';


const Mobilebar = () => {
    const config = useConfig();

    return (
        <div className="mobilebar">
            <div className="mobilebar__inner container">
                <Social position={'subheader'}/>
                <a className="mobilebar__phone analytics-mgo-number"
                   href={`tel:${config.primaryPhone}`}
                   onClick={() => reachGoal('phone-click', {from: 'mobile'})}>
                    {formatPhone(config.primaryPhone)}
                </a>
            </div>
        </div>
    );
};

export default Mobilebar;
