import React, {useEffect, useState} from 'react';
import Helmet from 'react-helmet';
import Section from 'app/components/section';
import Layout from 'app/components/layout';
import Intro from 'app/components/intro';
import {ServiceAPI} from "app/api/base";
import {useParams} from "react-router";
import FluidImg from "app/components/fluid-img";
import {Dna} from "app/components/dna";
import './service.scss'

function ServiceTemplate() {
    const [page, setPage] = useState({});
    const {id} = useParams();

    useEffect(() => {
        new ServiceAPI().retrieve(id).then(r => {
            setPage(r.data);
        })
    }, []);

    return (
        <Layout>
            <Helmet>
                <title data-react-helmet="true">{page.meta_title || page.title || page.name}</title>
                <meta name="description" content={page.meta_description || page.name} data-react-helmet="true"/>
                <meta name="keywords" content={page.meta_title || page.intro || page.name} data-react-helmet="true"/>
            </Helmet>

            {!page.name ?
                <Dna display={!page.name}/> :
                <Section>
                    <Intro title={page.name}/>
                    <div className={"service__inner container"}>
                        <div className={'service__figure a-dr-3 a-dl-1 a-e-in-out-spine'}>
                            <div>
                                <FluidImg img={page.img_for_main || page.img} className={'service__image'}/>
                            </div>
                        </div>

                        <div
                            className="service__content container container--narrow"
                            dangerouslySetInnerHTML={{__html: page.about_full || page.about_short}}
                        />
                    </div>
                </Section>}
        </Layout>
    );
}


export default ServiceTemplate;
