import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './intro.scss';

const Card = ({ title, text, icon: Icon, className }) => (
  <header className={classNames('intro container', className)}>
    {Icon && <Icon className="intro__icon" />}

    {title && (
      <h1 className="intro__title">
        {title}
      </h1>
    )}

    {text && (
      <p className="intro__text">
        {text}
      </p>
    )}
  </header>
);

Card.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.elementType,
  className: PropTypes.string,
};

Card.defaultProps = {
  title: null,
  text: null,
  icon: null,
  className: null,
};

export default Card;
