import {ScheduleAPI} from "app/api/base";
import useMember from "app/contexts/member";
import moment from "moment";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import Calendar from "react-calendar";
import {Busy, Fail} from "../main-form/fail";
import Form from "../main-form/form";
import Success from "../main-form/success";
import Modal from "../modal";
import "./calendar.scss";
import "./schedule-form.scss";
import {Times} from "./times";


function ScheduleForm({onClose, onSubmit}) {
    const [isSuccess, setIsSuccess] = useState(false);
    const [isFail, setIsFail] = useState(false);
    const [isBusy, setIsBusy] = useState(false);

    const {member, getTimes, times, gDateTime, setDateTime, dates} = useMember();

    const [dtTimes, setDtTimes] = useState([]);

    const [dateNotChoosen, setDateNotChoosen] = useState(false);
    const [timeNotChoosen, setTimeNotChoosen] = useState(false);

    useEffect(() => {
        getTimes();
        if (gDateTime)
            onChange(gDateTime);
    }, [isBusy]);

    function handleTryAgain() {
        setIsSuccess(false);
        setIsFail(false);
        setIsBusy(false);
    }

    function handleSubmit(values, {setSubmitting}) {
        onSubmit && onSubmit();

        setDateNotChoosen(false);
        setTimeNotChoosen(false);

        if (!gDateTime) {
            setTimeNotChoosen(true);
            setSubmitting(false);
        } else {
            const mmnt = moment(gDateTime)
            values.datetime = mmnt.format('YYYY-MM-DD H:mm:ss');

            new ScheduleAPI().appoint(
                member.name,
                values.name,
                values?.phone,
                values.datetime,
                member.id
            ).then(r => {
                    if (r.data === 'DONE')
                        setIsSuccess(true);
                    else if (r.data === 'BUSY')
                        setIsBusy(true);
                    else
                        setIsFail(true);
                    setDateTime(null);
                }).catch(() => setIsFail(true))
                .finally(() => setSubmitting(false));
        }
    }

    function onChange(v_date) {
        const cur_m = moment(v_date);
        const cur_dt = cur_m.format('YYYY-MM-DD');
        const tt = times.find(o => (cur_dt === o.date)).times.map(x => x.dtm);

        setDateTime(cur_m);
        setDtTimes(tt);
    }

    function Choose(time) {
        setDateTime(moment(time));
    }

    return (
        <Modal onClose={onClose}>
            <div className="schedule-form">
                <ul className={'calendar'}>
                    <li><Calendar onChange={onChange}
                                  value={gDateTime?gDateTime.toDate():null}
                                  view={'month'}
                                  locale={'RU'}
                                  tileDisabled={o => !dates.includes(o.date.valueOf())}
                    /></li>
                    {dateNotChoosen && (
                        <li className={'schedule-form__not-choosen'}>
                            Выберите день записи
                        </li>
                    )}

                    <li>
                        <Times times={dtTimes} onChoose={Choose}/>
                    </li>
                    {timeNotChoosen && (
                        <li className={'schedule-form__not-choosen'}>
                            Выберите время записи
                        </li>
                    )}
                </ul>

                <div className="schedule-form__content">
                    {isSuccess && <Success onClose={onClose}/>}

                    {isFail && <Fail onTryAgain={handleTryAgain}/>}
                    {isBusy && <Busy onTryAgain={handleTryAgain}/>}

                    {/* {isSMS && <SMSCode onSubmit={handleSMS}/>} */}

                    <Form title="Записаться" onSubmit={handleSubmit}/>
                </div>
            </div>
        </Modal>
    );
}

ScheduleForm.defaultProps = {
    onSubmit: null,
};

ScheduleForm.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
};

export default ScheduleForm;
