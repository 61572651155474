import React, {useState} from 'react';
import PropTypes from 'prop-types';
import './price.scss';
import Modal from "../modal";

function Price({title, items, ...props}) {
    const [description, setDescription] = useState(null);

    function routeClick(name, descr, landing) {
        if (landing)
            window.location.href = landing.link;
        else
            setDescription(descr);
    }

    return (
        <>
            {description && (
                <Modal onClose={() => setDescription(null)}>
                    <div className="figure figure--shadow window">
                        {/*<div>*/}
                        <div
                            className="content"
                            dangerouslySetInnerHTML={{__html: description}}
                        />
                    </div>
                    {/*<div>{description}</div>*/}
                </Modal>
            )}
            <div className="price__section" key={title}>
                <h3 className="price__title">{title}</h3>
                <div className="price__table-wrap">
                    <table className="price__table">
                        <tbody>
                        {items.map(({name, price, duration, duration_m, description, landing}) => (
                            <tr className="price__tr" key={`${name}${price}`}>
                                <td className="price__name">
                                    {description || landing
                                        ? <a href onClick={() => routeClick(name, description, landing)}>{name}</a> : <>{name}</>}
                                </td>

                                <td className="price__duration price__duration--cell">
                                    {description || landing
                                        ? <a href onClick={() => routeClick(name, description, landing)}>{duration_m || `${duration} мин.`}</a>
                                        : <>{duration_m || `${duration} мин.`}</>}
                                </td>

                                <td className="price__amount">
                                    {description || landing
                                        ? <a href onClick={() => routeClick(name, description, landing)}>{`${price} руб.`}</a>
                                        : <>{`${price} руб.`}</>}
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

Price.propTypes = {
    title: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    onClick: PropTypes.func
};

export default Price;
