import {useSeo} from "app/contexts/seo";
import React, {useEffect, useRef, useState} from "react";
import Helmet from "react-helmet";
import FluidImg from "app/components/fluid-img";
import Layout from "app/components/layout";
import Timeline from "app/components/timeline";
import Section from "app/components/section";
import Intro from "app/components/intro";
import "./physician.scss";
import ScheduleForm from "app/components/schedule-form";
import {TeamAPI} from "app/api/base";
import {useParams} from "react-router";
import useMember from "app/contexts/member";
import CachedImg from "app/components/cached-img";


const isEmtpyObject = obj => (
    Object.keys(obj).every(key => obj[key] === null)
);

const isEmptyList = array => (
    (array && typeof array === "object") ? (array.length === 0 || isEmtpyObject(array[0])) : true
);

function PhysicianTemplate() {
    const {id} = useParams();
    const [isSchedOpen, setSchedOpen] = useState(false);
    const {member, setMember, gDateTime, setDateTime} = useMember();
    // const {titles, setSeoName} = useSeo();
    const ref = useRef(null);

    // setSeoName(id);

    useEffect(() => {
        new TeamAPI().retrieve(id).then(r => {
            setMember(r.data);
            ref.current?.scrollIntoView({behavior: "smooth"});
        });
    }, []);


    return (
        <Layout>
            <Helmet>
                <meta name="author" content={member?.author} data-react-helmet="true"/>
                <title>{member?.meta_title || ""}</title>
                <meta name="description" content={member?.meta_description||""} data-react-helmet="true"/>
                <meta name="keywords" content={member?.meta_keywords||""} data-react-helmet="true"/>
            </Helmet>

            <Section ref_prop={ref}>
                <Intro title={member?.name}
                       text={member?.role}/>

                <div className="container split">
                    <aside className="split__secondary">
                        <CachedImg className="physician-page__photo figure" img={member?.photo}/>
                    </aside>

                    <div className="split__primary">
                        <button
                            className="button"
                            style={{marginLeft: 0, marginBottom: 20}}
                            type="button"
                            onClick={() => setSchedOpen(true)}
                        >
                            РАСПИСАНИЕ ВРАЧА + ЗАПИСЬ
                        </button>

                        {isSchedOpen
                            && <ScheduleForm onClose={() => setSchedOpen(false)}
                                             title="Расписание"/>}

                        <section className="content__section">
                            {/*<p className="content__text" dangerouslySetInnerHTML={{__html: member?.intro}}/>*/}
                            <p className="content__text">
                                {member?.intro}
                            </p>

                            {member?.about && (
                                <div
                                    className="content"
                                    dangerouslySetInnerHTML={{__html: member?.about}}
                                />
                            )}
                        </section>

                        {(member?.education || !isEmptyList(member?.edu_list)) && (
                            <section className="content__section">
                                <h3 className="content__title">
                                    Образование
                                </h3>

                                {member?.education && (
                                    <div
                                        className="content"
                                        dangerouslySetInnerHTML={{__html: member?.education}}
                                    />
                                )}

                                {member?.edu_list && (
                                    <Timeline items={member?.edu_list}/>
                                )}
                            </section>)}

                        {(member?.experience || !isEmptyList(member?.wrk_list)) && (
                            <section className="content__section">
                                <h3 className="content__title">
                                    Опыт работы
                                </h3>

                                {member?.experience && (
                                    <div
                                        className="content"
                                        dangerouslySetInnerHTML={{__html: member?.experience}}
                                    />
                                )}

                                {member?.wrk_list && (
                                    <Timeline items={member?.wrk_list} isBright/>
                                )}
                            </section>
                        )}
                    </div>
                </div>
            </Section>
        </Layout>
    );
}

export default PhysicianTemplate;

// export {MemberContext};
