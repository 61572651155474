import {useSeo} from "app/contexts/seo";
import React, {useEffect} from "react";
import {SymptomAPI} from "app/api/base";
import Card from "app/components/card";
import Intro from "app/components/intro";
import Layout from "app/components/layout";
import Section from "app/components/section";
import Benefits from "app/sections/benefits";
import BodyNav from "app/sections/body-nav";
import Helmet from "react-helmet";


const defaultCardType = {
    category: "Другое",
    color: "blue",
};

const cardTypes = [
    {
        category: "Голова и шея",
        color: "blue",
        parts: ["head", "neck"],
    },
    {
        category: "Верхние конечности",
        color: "orange",
        parts: ["shoulder", "elbow", "hand"],
    },
    {
        category: "Область спины",
        color: "indigo",
        parts: ["back", "waist"],
    },
    {
        category: "Область таза",
        color: "yellow",
        parts: ["hip"],
    },
    {
        category: "Нижние конечности",
        color: "red",
        parts: ["knee", "shin", "foot"],
    },
];

function getCardData(bodyPart) {
    const cardType = cardTypes.find(({parts}) => (
        parts.some(part => part === bodyPart)
    )) || defaultCardType;

    return {
        order: cardTypes.indexOf(cardType),
        category: cardType.category,
        color: cardType.color,
    };
}

function SymptomsPage() {
    const [cards, setCards] = React.useState([]);

    const {titles, setSeoName} = useSeo();


    useEffect(() => {
        setSeoName("symptoms");
        new SymptomAPI().list().then(r => {
            setCards(r.data
                      .filter(({name, photo}) => name && photo)
                      .map(({id, photo, seo_name, ...data}) => (
                          {
                              id, photo, seo_name,
                              title: data.name,
                              ...getCardData(data.body_part),
                          }))
                      .sort(({order: a}, {order: b}) => a - b));
        });
    }, []);

    return (
        <Layout>
            <BodyNav hasIcon={false}/>

            <Section color="gray" curve>
                <Intro
                    title={titles.meta_title}
                    text="Мы не только устраняем болевой синдром, но и добиваемся максимального восстановления нарушенных функций."
                />

                <ul className="card-list container">
                    {cards.map(({id, photo, title, seo_name}) => {
                        return <li className="card-list__item" key={id}>
                            <Card
                                link={`/symptoms/${seo_name || id}`}
                                photo={photo}
                                title={title}
                                simple
                            />
                        </li>;
                    })}
                </ul>
            </Section>

            <Benefits/>
        </Layout>
    );
}

export default SymptomsPage;
