// import Helmet from "react-helmet";
import {ReviewOuterSection} from "app/sections/review-outer-section";
import {useSeo} from "app/contexts/seo";
import React from "react";
import Layout from "app/components/layout";
import About from "app/sections/about";
import Benefits from "app/sections/benefits";
import BodyNav from "app/sections/body-nav";
import Certificates from "app/sections/certificates";
import Contacts from "app/sections/contacts";
import Diagnostics from "app/sections/diagnostics";
import FAQ from "app/sections/faq";
import MainHero from "app/sections/main-hero";
import Methods from "app/sections/methods";
import Reviews from "app/sections/reviews";
import Team from "app/sections/team";
import Virtual from "app/sections/virtual";


function AboutUsInner(){

    const {setSeoName} = useSeo();

    setSeoName('about-us');

    return <>
        <MainHero color="gray" curve/>
        <Benefits/>
        <BodyNav color="warm" curve/>
        <Methods/>
        <Diagnostics primaryOnly={true} merged/>
        <Team color="gray" curve/>
        <Reviews/>
        <Certificates color="warm"/>
        <ReviewOuterSection curve/>
        <FAQ/>
        <Contacts color="gray" curve/>
        <About/>
        <Virtual/>
    </>;
}

function AboutUsPage() {
    return <Layout>
        <AboutUsInner/>
    </Layout>;
}

export default AboutUsPage;
