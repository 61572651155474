import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {Link} from 'app/components/link';
import {ReactComponent as ArrowRight} from 'app/assets/svg/icons/arrow-right.svg';
import './arrow-control.scss';

const ArrowControl = ({ className, children, small, ...props }) => {
  let Component;

  if (props.to) {
    Component = Link;
  } else if (props.href) {
    Component = 'a';
  } else {
    Component = 'button';
  }

  return (
    <Component
      className={classNames('arrow-link', className, {
        'arrow-link--small': small,
      })}
      {...props}
    >
      {children}
      <ArrowRight className="arrow-link__arrow" />
    </Component>
  );
};

ArrowControl.propTypes = {
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
  to: PropTypes.string,
  href: PropTypes.string,
  small: PropTypes.bool,
};

ArrowControl.defaultProps = {
  className: null,
  to: null,
  href: null,
  small: false,
};

export default ArrowControl;
