import {Link} from "app/components/link";
import PropTypes from "prop-types";
import React from "react";
import CachedImg from "../cached-img";
import "./member.scss";


const E = 30;

function process_str(s) {
    const M = E - 3;
    let res = "";

    for (let i=0; i<E; i++) {
        if (i >= M)
            res += ".";
        else if (s[i])
            res += s[i];
    }
    return res;
}


function _split(string, N=3) {
    const S = 20;
    const ar = string.split(' ');
    let res = [],
        i=0,
        w=null,
        w_next=null;

    res[i] = [];

    if (string.length <= S)
        return [string]

    for (let j=0; j < ar.length; j++) {
        w = ar[j];
        w_next = ar[j+1];

        res[i].push(w);
        if ((res[i].join(" ").length > S) || (w_next && (res[i].concat([w_next]).join(" ").length > E))) {
            res[i] = res[i].join(' ');
            if (i >= N)
                break;
            i++;
            res[i] = [];
        }
    }
    if (Array.isArray(res[res.length-1]))
        res[res.length-1] = res[res.length-1].join(" ");
    if (res.join(" ").length < string.length) {
        res[res.length - 1] = process_str(res[res.length - 1]);
    }

    return res;
}

function split(role, intro, N=3) {
    let role_res = _split(role, N-1);
    let intro_res = null;

    if (role_res.length > 1) {
        N = 2;
    } else if (role_res.length == 1) {
        N = 3;
    }
    intro_res = _split(intro, N);

    return {role_map: role_res, intro_map: intro_res}
}

const Member = ({member}) => {

    const {name, role, intro} = member;
    const cur_len = name.length + role.length + intro.length;
    const {role_map, intro_map} = split(role, intro, 2);

    return <Link className="member" to={`/physicians/${member.seo_name || member.id}`}>
        <div className="member__photo">
            <CachedImg className="member__photo-inner" img={member.photo}/>
        </div>

        <div className="member__info">
            <h3 className="member__name">
                {name}
            </h3>

            {role_map.map(x => <p className="member__role">{x}</p>)}

            <p style={{marginBottom: 10}}/>

            {intro_map.map(x => <p className="member__about">{x}</p>)}
        </div>
    </Link>;
}

Member.propTypes = {
    member: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        role: PropTypes.string.isRequired,
        intro: PropTypes.string.isRequired,
        photo: PropTypes.object.isRequired,
        thumbnail: PropTypes.string.isRequired
    }).isRequired,
};

export default Member;
