import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import Section from 'app/components/section';
import FluidImg from 'app/components/fluid-img';
import {useBreakpoints} from 'app/contexts/breakpoints';
import Slider from 'app/components/slider';
import Modal from 'app/components/modal';
import Intro from 'app/components/intro';
import {ReactComponent as IntroSVG} from 'app/assets/svg/intro/certificates.svg';
import './certificates.scss';
import {CertificateAPI} from "app/api/base";
import tableImage from "app/assets/images/table.png";


const Certificates = (props) => {
    const [imageForModal, setImageForModal] = useState(null);
    const breakpoints = useBreakpoints();

    const [certificates, setCertificates] = useState([]);

    useEffect(() => {
        new CertificateAPI().list().then(res => {
            setCertificates(res.data)
        })
    }, []);


    return (
        <Section className="certificates" {...props}>
            {imageForModal && (
                <Modal onClose={() => setImageForModal(null)}>
                    <FluidImg img={imageForModal}/>
                </Modal>
            )}

            <Intro
                title="Свидетельства и сертификаты"
                icon={IntroSVG}
            />

            <Slider
                className="certificates__slider"
                itemsPerStep={breakpoints.s ? 3 : breakpoints.xs ? 2 : 1}
            >
                {certificates.map((cert) => (
                    <Slider.Item className="certificates__slider-item">
                        <button
                            className={classNames(
                                'certificates__item',
                                {'certificates__item--portrait': cert.is_portrait},//isPortrait(cert.thumbnail)},
                            )}
                            type="button"
                            onClick={() => setImageForModal(cert.img)}
                        >
                            <span className="certificates__bg"/>
                            <FluidImg img={cert.thumbnail}/>
                        </button>
                    </Slider.Item>
                ))}
            </Slider>

            <div className="certificates__table-wrap">
                <FluidImg
                    className="certificates__table"
                    img={tableImage}
                    loading="eager"
                    fadeIn={false}
                    aria-hidden
                />
            </div>
        </Section>
    );
};

export default Certificates;
