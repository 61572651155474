import PropTypes from "prop-types";
import React from "react";
import mainform from "app/assets/images/main-form.png";
import FluidImg from "app/components/fluid-img";
import {AnotherSEO} from "app/components/seo";
import {useBreakpoints} from "app/contexts/breakpoints";
import {ConfigProvider} from "app/contexts/config";
import Footer from "app/sections/footer";
import Header from "app/sections/header";


function Layout({children}) {

    const breakpoints = useBreakpoints();

    return (
        <ConfigProvider>
            <div style={{display: "none"}}>
                {breakpoints.m && (
                    <FluidImg img={mainform} aria-hidden/>
                    // <FluidImg img={imagesToPreload.formImage} aria-hidden />
                )}
            </div>

            <AnotherSEO/>

            {/*<ErrorBoundary>*/}
            <Header/>

            <main>{children}</main>

            <Footer/>
            {/*</ErrorBoundary>*/}
        </ConfigProvider>
    );
}

Layout.propTypes = {
    children: PropTypes.oneOfType([
                                      PropTypes.arrayOf(PropTypes.node),
                                      PropTypes.node,
                                  ]).isRequired,
};

export default Layout;
