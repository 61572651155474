import React from 'react';
import PropTypes from 'prop-types';
// import { useStaticQuery, graphql } from 'gatsby';
import {ReactComponent as TimeSVG} from 'app/assets/svg/benefits/time.svg';
import {ReactComponent as SofaSVG} from 'app/assets/svg/benefits/sofa.svg';
import {ReactComponent as TeamSVG} from 'app/assets/svg/benefits/team.svg';
import {ReactComponent as TechSVG} from 'app/assets/svg/benefits/tech.svg';
import {ReactComponent as SafetySVG} from 'app/assets/svg/benefits/safety.svg';
import {ReactComponent as FinanceSVG} from 'app/assets/svg/benefits/finance.svg';
import Section from 'app/components/section';
import './benefits.scss';
import {BenefitAPI} from "app/api/base";
import {containsHTML} from "app/utils/html";
import Spinner from "app/components/dna";

const ICONS = {
    time: TimeSVG,
    sofa: SofaSVG,
    team: TeamSVG,
    tech: TechSVG,
    safety: SafetySVG,
    finance: FinanceSVG,
};

const Benefit = ({icon: Icon, title, text}) => (
    <li className="benefits__item">
        {Icon &&
            <Icon className="benefits__icon"/>}

        <h3 className="benefits__title">
            {title}
        </h3>

        {containsHTML(text) ?
            <p className="content__text benefits__text"
               dangerouslySetInnerHTML={{__html: text}}/>
            :
            <p className={"content__text benefits__text"}>
                {text}
            </p>}
    </li>
);

Benefit.propTypes = {
    icon: PropTypes.elementType.isRequired,
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
};

function Benefits(props) {
    const [benefits, setBenefits] = React.useState([])

    React.useEffect(() => {
        new BenefitAPI().list().then(res => {
            setBenefits(res.data);
        });
    }, []);

    return (
        <Section {...props}>
            <div className="container">
                <Spinner spin={!benefits.length}>
                    <ul className="benefits__list">
                        {benefits.map(benefit => (
                            <Benefit
                                icon={ICONS[benefit.icon]}
                                title={benefit.title}
                                text={benefit.text}
                                key={benefit.icon}
                            />
                        ))}
                    </ul>
                </Spinner>
            </div>
        </Section>
    );
}

export default Benefits;
