import {PageAPI} from "app/api/base";
import {createContext, useContext, useEffect, useMemo, useState} from "react";


const SeoContext = createContext();


function useSeo() {
    return useContext(SeoContext);
}

function SeoProvider({children}) {
    const [seoname, _setSeoName] = useState("");
    const [titles, setTitles] = useState({});

    useEffect(() => {
        setTitles({});
        new PageAPI().retrieve(seoname).then(x => {
            setTitles(x.data);
        });
    }, [seoname]);

    useEffect(() => {
        if (titles.title)
            document.title = titles.title;
    }, [titles]);

    function setSeoName(val) {
        console.log(val);
        _setSeoName(val);
    }

    const data = useMemo(() => ({
        seoname, titles,

        setSeoName,
    }), [seoname, titles]);

    return <SeoContext.Provider value={data}>
        {children}
    </SeoContext.Provider>;

}


export {SeoProvider, useSeo};
