import React, {createContext, useContext, useMemo, useState} from 'react';
import {ScheduleAPI} from "app/api/base";
import moment from "moment";

const MemberContext = createContext();

export function MemberProvider({children}) {
    const [member, setMember] = useState(null);
    const [gDateTime, setDateTime] = useState();
    const [times, setTimes] = useState([]);
    const [dates, setDates] = useState([]);

    function getTimes(year, month) {
        const today = moment.utc();
        // new ScheduleAPI().list(member.id, year || today.format('YYYY'), month || today.format('M'))
        new ScheduleAPI().list_all(member.id)
            .then(res => {
                setDates(res.data.map(x => moment(x.date).valueOf()))
                setTimes(res.data);
            })
    }

    const value = useMemo(() => ({
        member, setMember,
        times, setTimes, getTimes,
        gDateTime, setDateTime,
        dates, setDates
    }), [
        member, setMember,
        times, setTimes, getTimes,
        gDateTime, setDateTime,
        dates, setDates
    ]);

    return <MemberContext.Provider value={value}>
        {children}
    </MemberContext.Provider>;
}

export default function useMember() {
    return useContext(MemberContext);
}
