import React, {createContext, useContext, useMemo} from 'react';
import {useMap} from 'react-use';

const PhotoContext = createContext();

export function PhotoProvider({children}) {
    const [photos, {set, remove}] = useMap({});

    const value = useMemo(() => ({
        photos, set, remove
    }), [
        photos, set, remove
    ]);

    return <PhotoContext.Provider value={value}>
        {children}
    </PhotoContext.Provider>;
}

export function usePhoto() {
    return useContext(PhotoContext);
}
