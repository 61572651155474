import React, { useState } from 'react';
import reachGoal from 'app/utils/reach-goal';
import MainForm from 'app/components/main-form';
import Topbar from 'app/sections/topbar';
import Navbar from 'app/sections/navbar';
import Mobilebar from 'app/sections/mobilebar';
import PopupNav from 'app/components/popup-nav';
import './header.scss';

function Header() {
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [isPopupNavOpen, setIsPopupNavOpen] = useState(false);

  function toggleModalForm() {
    setIsFormModalOpen(!isFormModalOpen);
  }

  function togglePopupNav() {
    setIsPopupNavOpen(!isPopupNavOpen);
  }

  return (
    <header className="header">
      {isPopupNavOpen && (
        <PopupNav
          onShowModalForm={toggleModalForm}
          onClose={togglePopupNav}
        />
      )}

      {isFormModalOpen && (
        <MainForm
          title="Записаться на прием"
          onSubmit={() => reachGoal('appointment-submit', { from: 'header' })}
          onClose={toggleModalForm}
        />
      )}

      <Topbar onShowPopupNav={togglePopupNav} />
      <Mobilebar />
      <Navbar onShowModalForm={toggleModalForm} />
    </header>
  );
}

export default Header;
