import React from 'react';
import PropTypes from 'prop-types';
import FluidImg from 'app/components/fluid-img';
import './card.scss';
import classNames from "classnames";

function FileItem({link, photo, title, children: text, simple}) {

    console.log(link);

    return <a href={link} className={classNames('card', {'card--simple': simple,})}>
        <div className="card__photo">
            <FluidImg className="card__photo-inner" img={photo}/>

            {simple && (
                <h3 className="card__photo-title">
                    {title}
                </h3>
            )}
        </div>

        {!simple && (
            <div className="card__content">
                <h3 className="card__title">
                    {title}
                </h3>
                {text && (
                    <p className="card__text">
                        {text}
                    </p>
                )}
            </div>
        )}
    </a>;
}

FileItem.propTypes = {
    link: PropTypes.string.isRequired,
    photo: PropTypes.oneOfType([
        PropTypes.shape({childImageSharp: PropTypes.object.isRequired}),
        PropTypes.shape({localFile: PropTypes.object.isRequired}),
    ]).isRequired,
    title: PropTypes.string.isRequired,
    children: PropTypes.string,
    simple: PropTypes.bool,
};

FileItem.defaultProps = {
    children: null,
    simple: false,
};

export default FileItem;
