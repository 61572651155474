import React from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import "./App.css";
import config from "./config";
import {BreakpointsProvider} from "app/contexts/breakpoints";
import {MemberProvider} from "app/contexts/member";
import {PhotoProvider} from "app/contexts/photos";
import {SeoProvider} from "app/contexts/seo";
import NotFoundPage from "./pages/404";
import AboutUsPage from "./pages/about-us";
import ContactPage from "./pages/contact";
import DiagnosticPage from "./pages/diagnostic";
import EducationPage from "./pages/education";
import PhysiciansPage from "./pages/physicians";
import PricingPage from "./pages/pricing";
import ServicesPage from "./pages/services";
import SymptomsPage from "./pages/symptoms";
import LandingTemplate from "./templates/landing/landing";
import PageTemplate from "./templates/page/page";
import PhysicianTemplate from "./templates/physician/physician";
import ServiceTemplate from "./templates/service/service";
import SymptomTemplate from "./templates/symptom/symptom";
import {ThemeProvider} from "app/themes/theme-provider";


function App() {
    return (
        <BreakpointsProvider breakpoints={config.breakpoints}>
            <ThemeProvider>
                <PhotoProvider>
                    <MemberProvider>
                        <SeoProvider>

                            <Router>
                                <Routes>
                                    <Route exact path="/" element={<AboutUsPage/>}>
                                        <Route exact path="about-us" element={<AboutUsPage/>}/>
                                    </Route>
                                    <Route path="/physicians">
                                        {/*<Routes>*/}
                                        <Route exact path={""} element={<PhysiciansPage/>}/>
                                        <Route path={":id"} element={<PhysicianTemplate/>}/>
                                        {/*</Routes>*/}
                                    </Route>
                                    {/*<Route exact path={'/physicians'} element={<PhysiciansPage/>}/>*/}
                                    <Route path={"/services"}>
                                        <Route exact path={""} element={<ServicesPage/>}/>
                                        <Route path={":id"} element={<ServiceTemplate/>}/>
                                    </Route>
                                    <Route path={"/symptoms"}>
                                        <Route exact path={""} element={<SymptomsPage/>}/>
                                        <Route path={":id"} element={<SymptomTemplate/>}/>
                                    </Route>
                                    <Route path={"/diagnostic"}>
                                        <Route exact path={""} element={<DiagnosticPage/>}/>
                                        <Route path={":id"} element={<ServiceTemplate/>}/>
                                        {/*<Route path={":id"} element={<DiagnosticTemplate/>}/>*/}
                                    </Route>
                                    <Route path={"/education"}>
                                        <Route exact path={""} element={<EducationPage/>}/>
                                        {/*<Route path={":id"} element={<ServiceTemplate/>}/>*/}
                                        {/*<Route path={":id"} element={<DiagnosticTemplate/>}/>*/}
                                    </Route>
                                    <Route path={"/pricing"}>
                                        <Route exact path={""} element={<PricingPage/>}/>
                                        {/*<Route path={":id"} element={<DiagnosticTemplate/>}/>*/}
                                    </Route>
                                    <Route path={"/lp/:id"} element={<LandingTemplate/>}/>
                                    <Route path={"/pages/:id"} element={<PageTemplate/>}/>
                                    <Route exact path={"/contact"} element={<ContactPage/>}/>

                                    <Route path="*" element={<NotFoundPage/>}/>
                                </Routes>
                            </Router>
                            <div id="modal"/>
                            <div id="popup"/>
                        </SeoProvider>

                    </MemberProvider>
                </PhotoProvider>
            </ThemeProvider>
        </BreakpointsProvider>);
}

export default App;
