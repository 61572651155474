import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FluidImg from 'app/components/fluid-img';
import ArrowControl from 'app/components/arrow-control';

const Method = ({method, isActive}) => (
    <div
        className={
            classNames(
                'methods__content split split--center a-slide-down-sm a-dr-4',
                {'is-active': isActive},
            )
        }
        style={(!isActive) ? {'display': 'none'} : {}}
    >
        <aside className="split__secondary">
            <FluidImg className="figure" img={method.img}/>
        </aside>

        <div className="split__primary">
            <h3 className="content__title">
                {method.name}
            </h3>

            <div
                className="content u-mb-12"
                dangerouslySetInnerHTML={{__html: method.about_short}}
            />

            <ArrowControl to={method.landing?.link || `/services/${method.seo_name || method.id}`}>
                Подробнее о методе
            </ArrowControl>
        </div>
    </div>
);

Method.propTypes = {
    isActive: PropTypes.bool.isRequired,
    method: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        about: PropTypes.object.isRequired,
        photo: PropTypes.object.isRequired,
        about_short: PropTypes.object,
        landing: PropTypes.object
    }).isRequired,
};

export default Method;
