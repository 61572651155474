import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useSwipeable} from 'react-swipeable';
import classNames from 'classnames';
import {ReactComponent as ArrowRightSVG} from 'app/assets/svg/icons/arrow-right.svg';
import './slider.scss';

function Slider({className, itemsPerStep, children}) {
    const [step, setStep] = useState(0);
    const availableSteps = Math.ceil(children.length / itemsPerStep) - 1;
    const canSlideLeft = step > 0;
    const canSlideRight = step < availableSteps;

    useEffect(() => {
        setStep(0);
    }, [itemsPerStep]);

    function slideLeft() {
        if (canSlideLeft) {
            setStep(s => s - 1);
        }
    }

    function slideRight() {
        if (canSlideRight) {
            setStep(s => s + 1);
        }
    }

    const swipeHandlers = useSwipeable({
        onSwipedLeft: slideRight,
        onSwipedRight: slideLeft,
        preventDefaultTouchmoveEvent: true,
    });

    const shift = step && -step * 100;

    return (
        <div className={classNames('slider', `slider--${itemsPerStep}`, className)}>
            <div className="slider__list-wrap">
                <ul
                    className="slider__list"
                    style={{transform: `translateX(${shift}%)`}}
                    {...swipeHandlers}
                >
                    {children}
                </ul>
            </div>

            {canSlideLeft && (
                <button
                    className="slider__arrow slider__arrow--left"
                    type="button"
                    aria-label="Листать влево"
                    onClick={slideLeft}
                >
                    <ArrowRightSVG/>
                </button>
            )}

            {canSlideRight && (
                <button
                    className="slider__arrow"
                    type="button"
                    aria-label="Листать влево"
                    onClick={slideRight}
                >
                    <ArrowRightSVG/>
                </button>
            )}
        </div>
    );
}

function SliderItem({className, children}) {
    return (
        <li className={classNames('slider__item', className)}>
            {children}
        </li>
    );
}

SliderItem.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};

SliderItem.defaultProps = {
    className: null,
};

Slider.propTypes = {
    itemsPerStep: PropTypes.oneOf([1, 2, 3]),
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};

Slider.defaultProps = {
    itemsPerStep: 1,
    className: null,
};

Slider.Item = SliderItem;

export default Slider;
