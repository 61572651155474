export const BODY_PARTS = [
  { id: 'head', name: 'Голова' },
  { id: 'neck', name: 'Шея' },
  { id: 'shoulder', name: 'Плечо' },
  { id: 'elbow', name: 'Локоть' },
  { id: 'hand', name: 'Кисть/Пальцы' },
  { id: 'back', name: 'Спина' },
  { id: 'waist', name: 'Поясница' },
  { id: 'hip', name: 'Таз/Копчик' },
  { id: 'knee', name: 'Колено' },
  { id: 'foot', name: 'Стопа' },
];
