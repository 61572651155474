import React from 'react';
import PropTypes from 'prop-types';
import {ReactComponent as SuccessSVG} from 'app/assets/svg/icons/success.svg';
import Button from 'app/components/button';

const Success = ({ onClose }) => (
  <div className="main-form__result a-fade a-dr-2">
    <SuccessSVG className="main-form__result-icon main-form__result-icon--success a-slide-up a-dl-1 a-dr-3 a-e-out-expo" />

    <h2 className="main-form__result-title">
      Заявка отправлена!
    </h2>

    <p className="main-form__result-text">
      Наш администратор перезвонит вам в течении 30 минут
      и запишет вас на удобное время  к специалисту.
    </p>

    <Button onClick={onClose}>
      Закрыть диалог
    </Button>
  </div>
);

Success.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default Success;
