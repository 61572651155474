import {useSeo} from "app/contexts/seo";
import React from "react";
// import Helmet from '../components/helmet';
import Layout from "app/components/layout";
import Contacts from "app/sections/contacts";
import Benefits from "app/sections/benefits";


function ContactPage() {
    const {setSeoName} = useSeo();

    setSeoName('contact')

    return <Layout>

        <Contacts color="gray" hasIcon={false} curve/>

        <Benefits/>
    </Layout>
}

export default ContactPage;
