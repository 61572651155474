import React, {useState, useEffect} from 'react';
import Section from 'app/components/section';
import {useBreakpoints} from 'app/contexts/breakpoints';
import Member from 'app/components/member';
import {ReactComponent as IntroSVG} from 'app/assets/svg/intro/team.svg';
import Slider from 'app/components/slider';
import Intro from 'app/components/intro';
import {TeamAPI} from "app/api/base";

function Team(props) {
    const breakpoints = useBreakpoints();
    const [members, setMembers] = useState([]);

    useEffect(() => {
        new TeamAPI().list().then(r => {
            setMembers(r.data);
        })
    },[]);

    console.log("breakpoints=",breakpoints);

    return (
        <Section {...props}>
            <Intro
                title="Специалисты клиники"
                text="В нашей команде собрались настоящие профессионалы своего дела, со средним стажем более 11 лет."
                icon={IntroSVG}
            />

            <Slider itemsPerStep={breakpoints.xl ? 3 : breakpoints.m ? 2 : 1}>
                {members.map(member => (
                    <Slider.Item key={member.id}>
                        <Member member={member}/>
                    </Slider.Item>
                ))}
            </Slider>
        </Section>
    );
};

export default Team;
