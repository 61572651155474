import axios from "app/axiosconf/axios";
import {usePhoto} from "app/contexts/photos";
import env from "environment";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {Dna} from "./dna";


function imageEncode(arrayBuffer) {
    let u8 = new Uint8Array(arrayBuffer)
    let b64encoded = btoa([].reduce.call(new Uint8Array(arrayBuffer), function (p, c) {
        return p + String.fromCharCode(c)
    }, ''))
    let mimetype = "image/jpeg"
    return "data:" + mimetype + ";base64," + b64encoded
}

function CachedImg({img, alt, style, ...props}) {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const {photos, set, remove} = usePhoto();
    let img_file = img?.localFile?.childImageSharp?.fluid || img?.childImageSharp?.fluid || img,
        full_img_path = null;

    useEffect(() => {
        setLoading(true);
        if (img_file) {

            if (env.isLocal && !img_file.includes("http://localhost") && !img_file.includes("data:")) {
                if (img_file.includes('static'))
                    full_img_path = `http://localhost:3000${img_file}`;
                else
                    full_img_path = `http://localhost:8000${img_file}`;
            } else {
                full_img_path = img_file;
            }

            if (!full_img_path.includes("data:")) {
                const _data = photos[full_img_path];

                if (!_data) {
                    axios.get(full_img_path, {responseType: "arraybuffer"})
                        .then(resp => {
                            const __data = imageEncode(resp.data);
                            set(full_img_path, __data);
                            setData(__data)
                            setLoading(false)
                        })
                        .catch(err => {
                        });
                } else {
                    setData(_data);
                    setLoading(false)
                }
            } else {
                setData(full_img_path);
            }
        }
        return () => {
            setData(null);
            setLoading(true);
        };
    }, [img]);

    if (!img)
        return null;

    return <>
        {loading ?
            <Dna display={loading}/> :
            <img alt={alt}
                 src={data}
                 {...props}/>}
    </>

}

CachedImg.propTypes = {
    img: PropTypes.oneOfType([
        PropTypes.shape({childImageSharp: PropTypes.object.isRequired}),
        PropTypes.shape({localFile: PropTypes.object.isRequired}),
        PropTypes.string
    ]).isRequired,
};

export default CachedImg;
