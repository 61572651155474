import {useSeo} from "app/contexts/seo";
import React from 'react';
import PropTypes from 'prop-types';
import {useConfig} from 'app/contexts/config';
import formatPhone from 'app/utils/format-phone';
// import ViewportMonitor from 'app/components/viewport-monitor';
import Section from 'app/components/section';
import {ReactComponent as IntroSVG} from 'app/assets/svg/intro/contacts.svg';
import {ReactComponent as PlaceSVG} from 'app/assets/svg/icons/place.svg';
import {ReactComponent as CarSVG} from 'app/assets/svg/icons/car.svg';
import {ReactComponent as WalkingSVG} from 'app/assets/svg/icons/walking.svg';
import {ReactComponent as ClockSVG} from 'app/assets/svg/icons/clock.svg';
import {ReactComponent as PhoneSVG} from 'app/assets/svg/icons/phone.svg';
import Intro from 'app/components/intro';
import {Map, Placemark, YMaps} from 'react-yandex-maps';
import './contacts.scss';
import {AddressContactsItem, ContactsItem, WorkTimes} from "app/components/contacts/contacts";


const Contacts = ({hasIcon, ...props}) => {
    const config = useConfig();

    const {titles} = useSeo();

    return (
        <Section {...props}>
            <Intro
                title={titles.meta_title}
                icon={hasIcon ? IntroSVG : null}
            />

            <div className="container contacts">
                <ul className="contacts__list">
                    <AddressContactsItem position={'contacts'}/>

                    <ContactsItem title="На машине" icon={CarSVG}>
                        <a className="link"
                           href="https://yandex.ru/maps/-/CGvVEQ3c"
                           target="_blank"
                           rel="noopener noreferrer">
                            Маршрут Яндекс Карты
                        </a>
                        <br/>
                        <a
                            className="link"
                            href="https://goo.gl/maps/7pfyZxW3Ao1FmPvu5"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Маршрут Google Maps
                        </a>
                    </ContactsItem>

                    {/*<ContactsItem title="Пешком" icon={WalkingSVG}>*/}
                    {/*    <b>7 минут</b>*/}
                    {/*    {' '}*/}
                    {/*    от станции*/}
                    {/*    <br/>*/}
                    {/*    метро “Уралмаш”*/}
                    {/*</ContactsItem>*/}

                    {/*<ContactsItem title="Время работы" icon={ClockSVG}>*/}
                    {/*    Пн-Пт&nbsp;*/}
                    {/*    <b>8:00-20:00</b>*/}
                    {/*    <br/>*/}
                    {/*    Сб-Вс&nbsp;&thinsp;*/}
                    {/*    <b>8:00-17:00</b>*/}
                    {/*</ContactsItem>*/}
                    <WorkTimes/>

                    <ContactsItem title="Телефоны" icon={PhoneSVG}>
                        <b>
                            <a className="link analytics-mgo-number" href={`tel:${config.primaryPhone}`}>
                                {formatPhone(config.primaryPhone)}
                            </a>
                        </b>
                        <br/>
                        <b>
                            <a className="link analytics-add-number" href={`tel:${config.secondaryPhone}`}>
                                {formatPhone(config.secondaryPhone)}
                            </a>
                        </b>
                    </ContactsItem>
                </ul>

                <div className="contacts__map">
                    <div className="contacts__map-inner">
                        {/*<ViewportMonitor once>*/}
                        {/*    {isInViewport => isInViewport && (*/}
                        <YMaps>
                            <Map defaultState={{center: [56.895995, 60.590515], zoom: 14}} width="100%" height="100%">
                                <Placemark geometry={[56.889781, 60.600350]}/>
                                <Placemark geometry={[56.889281, 60.601700]}/>
                                <Placemark geometry={[56.902681, 60.583300]}/>
                            </Map>
                        </YMaps>
                        {/*    )}*/}
                        {/*</ViewportMonitor>*/}
                    </div>
                </div>
            </div>
        </Section>
    );
};

Contacts.defaultProps = {
    hasIcon: true,
};

Contacts.propTypes = {
    hasIcon: PropTypes.bool,
};

export default Contacts;
