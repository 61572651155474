// You must install `gatsby-plugin-yandex-metrika` to the function.

export default function reachGoal(goalId, goalParams) {
  if (
    process.env.NODE_ENV === 'production'
    && typeof window.yaCounter !== 'undefined'
    && typeof window.yaCounter.reachGoal === 'function'
  ) {
    window.yaCounter.reachGoal(goalId, goalParams);
  }
}
