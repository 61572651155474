import {Dna as _Dna} from "react-loader-spinner";


export function Dna({display=false}) {
    if (display)
        return <_Dna visible={true}
                     height="80"
                     width="100%"
                     ariaLabel="dna-loading"
                     wrapperStyle={{}}
                     wrapperClass="dna-wrapper"/>;
    else return null;
}

export default function Spinner({children, spin = true}) {
    const styles = {
        width: "100%",
        height: `${window.innerHeight / 2}px`,
        padding: `${window.innerHeight / 5}px`
    };

    if (!spin)
        // if (false)
        return children;
    else
        return <div style={styles}>
            <_Dna visible={true}
                  height="80"
                  width="100%"
                  ariaLabel="dna-loading"
                  wrapperStyle={{}}
                  wrapperClass="dna-wrapper"/>
        </div>;
}
