import React from 'react';
import FluidImg from "../fluid-img";
import vertour from "app/assets/images/vertebro-tour.png";
import './virtual-tour.scss'
import "app/sections/main-hero/main-hero.scss"


const yandex_tour = '<div style="position:relative;overflow:hidden;">' +
    '<a href="https://yandex.ru/maps/org/vertebrotsentr/19385814515/?utm_medium=mapframe&utm_source=maps" ' +
        'style="color:#eee;font-size:12px;position:absolute;top:0px;">' +
        'Вертеброцентр</a>' +
    '<a href="https://yandex.ru/maps/54/yekaterinburg/category/medical_center_clinic/184106108/?utm_medium=mapframe&utm_source=maps" ' +
        'style="color:#eee;font-size:12px;position:absolute;top:14px;">' +
        'Медцентр, клиника в Екатеринбурге</a>' +
    '<a href="https://yandex.ru/maps/54/yekaterinburg/category/massage_salon/184106074/?utm_medium=mapframe&utm_source=maps" ' +
        'style="color:#eee;font-size:12px;position:absolute;top:28px;">' +
        'Массажный салон в Екатеринбурге</a>' +
    '<iframe src="https://yandex.ru/map-widget/v1/?ll=60.604262%2C56.895512&mode=search&oid=19385814515&ol=biz&panorama%5Bdirection%5D=' +
        '281.627616%2C-4.584814&panorama%5Bfull%5D=true&panorama%5Bpoint%5D=60.602686%2C56.889126&panorama%5Bspan%5D=117.097550%2C60.000000&sll=60.601479%2C56.889308' +
    '&sspn=0.072527%2C0.034389&text=%D0%92%D0%B5%D1%80%D1%82%D0%B5%D0%B1%D1%80%D0%BE%D1%86%D0%B5%D0%BD%D1%82%D1%80%09%D0%95%D0%BA%D0%B0%D1%82%D0%B5%D1%80%D0%B8%D' +
    '0%BD%D0%B1%D1%83%D1%80%D0%B3%2C%20%D0%9A%D0%B8%D1%80%D0%BE%D0%B2%D0%B3%D1%80%D0%B0%D0%B4%D1%81%D0%BA%D0%B0%D1%8F%2C%2012&utm_source=share&z=15" width="560"' +
    ' height="400" frameborder="1" allowfullscreen="true" style="position:relative;display:block"></iframe></div>';

export default function VirtualTour({}) {
    return <div className={"virtual-tour"}
                // dangerouslySetInnerHTML={ { __html: yandex_tour}}
                style={{height: 400}}
    >
        <a href={"https://yandex.ru/maps/-/CCURV4HZtA"} target="_blank">
            <div className={'virtual-div'}>
                <button className={'virtual-text button'}>Виртуальный тур</button>
            </div>
            <FluidImg img={vertour}
                      className={"main-hero__video"}
                      style={{marginTop: 0}}
            />
        </a>
        <span className="main-hero__video-text"
              style={{
                  marginTop: "-20px",
                  width: "100%",
                  textAlign: "center"
              }}>Познакомтесь с нашей клиникой
                    <a href={"https://yandex.ru/maps/-/CCURV4HZtA"}
                       target="_blank" style={{display: 'inline'}}
                       className="u-text-italic main-hero__video-text"> виртуально</a>
        </span>
    </div>
        ;
}
