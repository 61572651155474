import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './section.scss';

const Section = ({
                     children,
                     className,
                     color,
                     gradient,
                     curve,
                     merged,
                     ref_prop,
                     ...props
                 }) => (
    <section
        className={classNames('section', className, {
            [`section--${color}`]: color,
            'section--gradient': gradient,
            'section--curve': curve,
            'section--merged': merged,
        })}
        {...props}
        ref={ref_prop}
    >
        {children}
    </section>
);

Section.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    color: PropTypes.string,
    gradient: PropTypes.bool,
    curve: PropTypes.bool,
    merged: PropTypes.bool,
};

Section.defaultProps = {
    className: null,
    color: null,
    gradient: false,
    curve: false,
    merged: false,
};

export default Section;
