import {useSeo} from "app/contexts/seo";
import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Section from 'app/components/section';
import {ReactComponent as IntroSVG} from 'app/assets/svg/intro/body-nav.svg';
import {BODY_PARTS} from 'app/constants';
import Intro from 'app/components/intro';
import TextNav from './text-nav';
import Body from './body';
import Symptoms from './symptoms';
import './body-nav.scss';
import {SymptomAPI} from "app/api/base";

function normalizeData(symptoms) {
    const map = {};

    symptoms.forEach(({id, name, body_part, seo_name}) => {
        if (!map[body_part]) {
            map[body_part] = [];
        }

        map[body_part].push({
            id, name, seo_name
        });
    });

    return map;
}

const BodyNav = ({hasIcon, ...props}) => {
    const [activeBodyPart, setActiveBodyPart] = useState(BODY_PARTS[0]);
    // const [data, setData] = useState([]);
    const [bodyPartToSymptoms, setBodyPartToSymptoms] = useState([]);

    const {titles} = useSeo();


    useEffect(() => {
        // setSeoName('symptoms');
        new SymptomAPI().list().then(res => {
            // setData(res.data);
            setBodyPartToSymptoms(normalizeData(res.data));
        });
    },[]);


    const isActiveBodyPart = ({id}) => (
        id === activeBodyPart.id
    );

    return (
        <Section className="body-nav" {...props}>
            <Intro
                title={titles.meta_title}
                icon={hasIcon ? IntroSVG : null}
            />

            <div className="body-nav__inner container">
                <TextNav
                    isActiveBodyPart={isActiveBodyPart}
                    onSelectBodyPart={setActiveBodyPart}
                />

                <Body
                    isActiveBodyPart={isActiveBodyPart}
                    onSelectBodyPart={setActiveBodyPart}
                />

                <Symptoms symptoms={bodyPartToSymptoms[activeBodyPart.id] || []}/>
            </div>
        </Section>
    );
};

BodyNav.defaultProps = {
    hasIcon: true,
};

BodyNav.propTypes = {
    hasIcon: PropTypes.bool,
};

export default BodyNav;
