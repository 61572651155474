import React from 'react';
import PropTypes from 'prop-types';
import range from 'lodash/range';
import {ReactComponent as StarSVG} from 'app/assets/svg/icons/star.svg';
import './star-meter.scss';

const MAX_STARS_NUMBER = 5;

const StarMeter = ({ stars: starsNumber }) => {
  const emptyStarsNumber = MAX_STARS_NUMBER - starsNumber;

  return (
    <div className="star-meter">
      {range(starsNumber).map(key => (
        <StarSVG
          className="star-meter__star"
          key={key}
        />
      ))}
      {range(emptyStarsNumber).map(key => (
        <StarSVG
          className="star-meter__star star-meter__star--empty"
          key={key}
        />
      ))}
    </div>
  );
};

StarMeter.defaultProps = {
  stars: 0,
};

StarMeter.propTypes = {
  stars: PropTypes.oneOf([0, 1, 2, 3, 4, 5]),
};

export default StarMeter;
