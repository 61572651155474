import React from 'react';
import Section from 'app/components/section';
import VirtualTour from "app/components/virtual-tour/virtual-tour";

function Virtual({...props}) {
    return (
        <Section color="gray">
            <VirtualTour/>
        </Section>
    );
}

export default Virtual;
