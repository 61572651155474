import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../modal';
import './video-modal.scss';

const VideoModal = ({ youtubeID, onClose, title }) => (
  <Modal onClose={onClose}>
    <div className="video-modal">
      <div className="video-modal__inner">
        <iframe
          title={title}
          className="video-modal__iframe"
          src={`https://www.youtube.com/embed/${youtubeID}?autoplay=1`}
          allow="autoplay"
          allowFullScreen
        />
      </div>
    </div>
  </Modal>
);

VideoModal.defaultProps = {
  title: '',
};

VideoModal.propTypes = {
  youtubeID: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
};

export default VideoModal;
