import React from 'react';
import PropTypes from 'prop-types';
import Section from 'app/components/section';
import FluidImg from 'app/components/fluid-img';
import ArrowControl from 'app/components/arrow-control';
import {LandingSectionAPI} from "app/api/base";
import reception from 'app/assets/images/about/reception.jpg';

function About({hasIcon, ...props}) {
    const [images, setImages] = React.useState([]);

    React.useEffect(() => {
        new LandingSectionAPI().list({section: "about"}).then(res => {
            setImages(res.data);
        });
    },[])

    return (
        <Section {...props}>
            <div className="container split split--center">
                <aside className="split__half">
                    <FluidImg
                        className="figure figure--shadow"
                        img={reception}
                        alt="Вход в клинику"
                    />
                </aside>

                <div className="split__half">
                    <div>
                        <h3 className="content__title">
                            В нашей клинике Вам доступны
                        </h3>

                        <ul className="content__list">
                            <li className="content__list-item">
                                <b>Консультация невролога, остеопата</b> и других врачей.
                            </li>

                            <li className="content__list-item">
                                Лечение заболеваний <b>позвоночника и суставов</b>.
                            </li>

                            <li className="content__list-item">
                                Лечение <b>головной боли и головокружения</b>.
                            </li>

                            <li className="content__list-item">
                                Реабилитация после травм и операций.
                            </li>

                            <li className="content__list-item">
                                Лечение депрессии и панических атак.
                            </li>
                        </ul>

                        <p className="content__text">
                            <ArrowControl href="#methods-section">
                                Смотреть методы лечения
                            </ArrowControl>
                        </p>
                    </div>
                </div>
            </div>
        </Section>
    );
}

About.defaultProps = {
    hasIcon: true,
};

About.propTypes = {
    hasIcon: PropTypes.bool,
};

export default About;
