import {SymptomAPI} from "app/api/base";
import Intro from "app/components/intro";
import Layout from "app/components/layout";
import Section from "app/components/section";
import React, {useEffect, useState} from "react";
import Helmet from "react-helmet";
import {useParams} from "react-router";


function SymptomTemplate() {
    const [page, setPage] = useState({});
    const {id} = useParams();

    document.title = page.meta_title || page.name;

    useEffect(() => {
        new SymptomAPI().retrieve(id).then(r => {
            setPage(r.data);
        });
    }, []);

    return (
        <Layout>
            <Helmet>
                <title>{page.meta_title || page.name}</title>
                <meta name="description" content={page.meta_description || page.name}/>
                <meta name="keywords" content={page.meta_keywords || page.name}/>
            </Helmet>
            <Section>
                <Intro title={page.name || page.meta_title}/>

                <div
                    className="content container container--narrow"
                    dangerouslySetInnerHTML={{__html: page.about}}
                />
            </Section>
        </Layout>
    );
}


export default SymptomTemplate;
