import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import {HotKeys} from 'react-hotkeys';
import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock';
import {ReactComponent as BurgerSVG} from 'app/assets/svg/icons/burger-close.svg';
import './modal.scss';

class Modal extends React.Component {
    constructor(props) {
        super(props);

        this.rootRef = React.createRef();
        this.contentRef = React.createRef();
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
        this.handleESCPress = this.handleESCPress.bind(this);
    }

    componentDidMount() {
        const rootElement = this.rootRef.current;
        disableBodyScroll(this.rootRef.current, {reserveScrollBarGap: true});
        rootElement.focus();
    }

    componentWillUnmount() {
        enableBodyScroll(this.rootRef.current);
    }

    handleOutsideClick({target}) {
        const contentEl = this.contentRef.current;
        const {onClose} = this.props;

        if (target === contentEl || contentEl.contains(target)) {
            return;
        }

        onClose();
    }

    handleESCPress({target}) {
        const rootElement = this.rootRef.current;
        const {onClose} = this.props;

        if (target !== rootElement) {
            rootElement.focus();
        } else {
            onClose();
        }
    }

    // waitForAnimations() {
    //     const rootElement = this.rootRef.current;
    //
    //     return new Promise((resolve) => {
    //         window.requestAnimationFrame(() => {
    //             const {animationName, animationPlayState} = window.getComputedStyle(rootElement);
    //
    //             if (animationName === 'none' && animationPlayState !== 'running') {
    //                 resolve();
    //             }
    //
    //             rootElement.addEventListener('animationend', resolve, {once: true});
    //         });
    //     });
    // }

    render() {
        const {children} = this.props;

        const content = (
            <HotKeys keyMap={{ESC: 'esc'}} handlers={{ESC: this.handleESCPress}}>
                <section
                    className="modal modal--centered"
                    ref={this.rootRef}
                    role="dialog"
                    aria-modal="true"
                    tabIndex="-1"
                    onClick={this.handleOutsideClick}
                >
                    <button className="modal__close-button" type="button" aria-label="Закрыть">
                        <BurgerSVG className="modal__close-icon"/>
                    </button>

                    <div className="modal__inner a-zoom-out a-dr-2 a-e-in-out-quad">
                        <div className="modal__content" ref={this.contentRef}>
                            {children}
                        </div>
                    </div>
                </section>
            </HotKeys>
        );

        return ReactDOM.createPortal(content, document.getElementById('modal'));
    }
}

Modal.propTypes = {
    onClose: PropTypes.func.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};

export default Modal;
