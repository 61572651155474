import React, {useEffect, useState} from 'react';
import Helmet from 'react-helmet';
import Layout from 'app/components/layout';
import Benefits from 'app/sections/benefits';
import {FileItem} from 'app/components/card';
import Section from 'app/components/section';

import {EducationAPI} from 'app/api/base';
import Spinner from "app/components/dna";

export default function EducationPage() {
    const [items, setItems] = useState([]);

    useEffect(() => {
        new EducationAPI().list().then(res => {
            setItems(res.data);
        })
    }, []);

    return <Layout>
        <Helmet>
            <title>Образование</title>
        </Helmet>

        <Spinner spin={!items?.length}>
            <Section color="gray" curve className={'disable_top_padding'}>
                <React.Fragment key={'Diags'}>
                    <ul className="card-list container">
                        {items.map(({id, img, name, intro, file}) => (
                            <li className="card-list__item" key={id}>
                                <FileItem link={file}
                                          photo={img}
                                          title={name}
                                          simple={false}>
                                    {intro}
                                </FileItem>
                            </li>
                        ))}
                    </ul>
                </React.Fragment>
            </Section>
        </Spinner>

        <Benefits/>
    </Layout>
}
