export default {
  siteNoscriptText: 'Включите JavaScript, для корректной работы сайта.',

  navLinks: [
    { name: 'О клинике', to: '/' },
    { name: 'Специалисты', to: '/physicians/' },
    { name: 'Наши услуги', to: '/services/' },
    { name: 'Заболевания', to: '/symptoms/' },
    { name: 'Диагностика', to: '/diagnostic/' },
    { name: 'Наши цены', to: '/pricing/' },
    { name: 'Образование', to: '/education/' },
    { name: 'Контакты', to: '/contact/' },
  ],

  breakpoints: {
    xs: '(min-width: 480px)',
    s: '(min-width: 768px)',
    m: '(min-width: 920px)',
    l: '(min-width: 960px)',
    xl: '(min-width: 1060px)',
    xxl: '(min-width: 1200px)',
  },

  googleMaps: {
    language: 'ru',
    key: process.env.GOOGLE_MAPS_API_KEY,
  },
  //
  // functionsUrls: {
  //   callRequest: `${window.location.scheme}://${window.location.host}/callRequest`,
  // },

  sentry: {
    dsn: process.env.SENTRY_DSN,
  },
};
