

export default function EnviromentSettings(baseApi) {
    return {
      api: baseApi,
      isProduction: true,
      isLocal: false,
      isDevelopment: false,
    };
  }
