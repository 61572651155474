import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Spinner from 'app/components/spinner';
import './button.scss';

const Button = ({
  children,
  className,
  big,
  small,
  full,
  accent,
  processing,
  ...props
}) => (
  <button
    className={classNames('button', className, {
      'button--big': big,
      'button--small': small,
      'button--full': full,
      'button--accent': accent,
      'is-disabled': processing,
    })}
    type="button"
    {...props}
  >
    {processing ? <Spinner /> : children}
  </button>
);

Button.propTypes = {
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
  big: PropTypes.bool,
  small: PropTypes.bool,
  full: PropTypes.bool,
  accent: PropTypes.bool,
  processing: PropTypes.bool,
};

Button.defaultProps = {
  className: null,
  big: false,
  small: false,
  full: false,
  accent: false,
  processing: false,
};

export default Button;
