import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {ReactComponent as BodySVG} from 'app/assets/svg/illustrations/body.svg';
import { BODY_PARTS } from 'app/constants';

const Body = ({ isActiveBodyPart, onSelectBodyPart }) => (
  <div className="body-nav__body body-nav__section" data-sal="zoom-out">
    <BodySVG aria-label="Тело человека" />

    <ul>
      {BODY_PARTS.map(bodyPart => (
        <li key={bodyPart.id}>
          <button
            className={
              classNames(
                'body-nav__body-circle',
                `body-nav__body-circle--${bodyPart.id}`,
                { 'is-active': isActiveBodyPart(bodyPart) },
              )
            }
            type="button"
            aria-label={bodyPart.name}
            onClick={() => onSelectBodyPart(bodyPart)}
          />
        </li>
      ))}
    </ul>
  </div>
);

Body.propTypes = {
  isActiveBodyPart: PropTypes.func.isRequired,
  onSelectBodyPart: PropTypes.func.isRequired,
};

export default Body;
