import React from 'react';
import PropTypes from 'prop-types';
import { Field as FormikField, ErrorMessage } from 'formik';
import classNames from 'classnames';

const Field = ({
  className,
  name,
  type,
  placeholder,
  labelText,
  labelClassName,
  errorClassName,
}) => {
  const labelId = `${name}-field`;

  return (
    <>
      <label htmlFor={labelId} className={labelClassName}>
        {labelText}
      </label>

      <FormikField name={name}>
        {({ field, form }) => (
          <input
            className={classNames(className, {
              'has-error': form.touched[name] && form.errors[name],
            })}
            id={labelId}
            type={type}
            name={name}
            placeholder={placeholder}
            {...field}
          />
        )}
      </FormikField>

      <ErrorMessage
        className={`${errorClassName} a-fade a-df`}
        name={name}
        component="div"
      />
    </>
  );
};

Field.defaultProps = {
  type: 'text',
  placeholder: null,
  className: null,
  labelClassName: null,
  errorClassName: PropTypes.string,
};

Field.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  labelText: PropTypes.string.isRequired,
  labelClassName: PropTypes.string,
  errorClassName: PropTypes.string,
};

export default Field;
