import React, {useEffect, useState} from 'react';
import Helmet from 'react-helmet';
import Section from 'app/components/section';
import Layout from 'app/components/layout';
import Hero from 'app/components/hero';
import About from 'app/sections/about';
import BodyNav from 'app/sections/body-nav';
import Methods from 'app/sections/methods';
import Diagnostics from 'app/sections/diagnostics';
import Team from 'app/sections/team';
import Reviews from 'app/sections/reviews';
import Benefits from 'app/sections/benefits';
import Certificates from 'app/sections/certificates';
import FAQ from 'app/sections/faq';
import Contacts from 'app/sections/contacts';
import {LandingAPI, LandingSectionAPI} from "app/api/base";
import {useParams} from "react-router";

const SLICES = {
    about: About,
    bodyparts: BodyNav,
    methods: Methods,
    diagnostic: Diagnostics,
    team: Team,
    reviews: Reviews,
    benefits: Benefits,
    certificates: Certificates,
    qas: FAQ,
    contacts: Contacts,
};

function normalizeSliceType(sliceType) {
    return sliceType.replace('PrismicLandingBody', '').toLowerCase();
}

function LandingTemplate() {
    const [landing, setLanding] = useState({});
    const [slices, setSlices] = useState([]);
    const {id} = useParams();

    useEffect(() => {
        new LandingAPI().retrieve(id).then(r => {
            const lnd = r.data;
            setLanding(lnd);
            new LandingSectionAPI().list({'landing_id': lnd.id}).then(r => {
                setSlices(r.data.map(s => SLICES[s.section]));
            })
        })
    }, []);

    return (
        <Layout>
            <Helmet>
                <title>{landing.title}</title>
                <meta name="description" content={landing?.meta_description}/>
            </Helmet>

            <Hero
                title={landing.title}
                benefits={landing.benefits}
                ctaText={landing.cta_text}
                image={landing.img}
            />

            <Section>
                <div
                    className="content container container--narrow"
                    dangerouslySetInnerHTML={{__html: landing.about}}
                />
            </Section>

            {slices && slices.map((Slice, index) => {
                return <Slice
                    key={index}
                    {...index % 2 === 0 ? {color: 'gray'} : {}}
                />
            })}
        </Layout>
    );
}

export default LandingTemplate;
