import {useSeo} from "app/contexts/seo";
import React from "react";
import {Helmet} from "react-helmet";

// import Helmet from './helmet';

//
// const SEO = () => {
//     const config = useConfig();
//
//     // return <Helmet seo_name={'about-us'}/>
//     return (
//         <Helmet>
//             {config.author && <meta name="author" content={config.author}/>}
//             {config.defaultDescription && <meta name="description" content={config.defaultDescription}/>}
//             {config.defaultKeywords && <meta name="keywords" content={config.defaultKeywords}/>}
//         </Helmet>
//     );
// };


function AnotherSEO() {

    const {titles} = useSeo();

    return <Helmet>
        <meta name="author" content={titles?.author} data-react-helmet="true"/>
        <meta name="description" content={titles?.meta_description} data-react-helmet="true"/>
        <meta name="keywords" content={titles?.meta_keywords} data-react-helmet="true"/>
        <title data-react-helmet="true">{titles?.meta_title}</title>
    </Helmet>;
}


// export default SEO;
export {AnotherSEO};
