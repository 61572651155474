import React, {useState} from "react";
import {Link} from "app/components/link";
import {useConfig} from "app/contexts/config";
import formatPhone from "app/utils/format-phone";
import reachGoal from "app/utils/reach-goal";
import MainForm from "app/components/main-form";
import {ReactComponent as LogoSVG} from "app/assets/svg/logo/main.svg";
// import VkontakteSVG from '/assets/svg/icons/vk.com.svg';
// import FacebookSVG from '/assets/svg/icons/facebook.svg';
// import InstagramSVG from '/assets/svg/icons/instagram.svg';
import Button from "app/components/button";
import "./footer.scss";
import Social from "app/components/social/social";
import {AddressContactsItem, WorkTimes} from "app/components/contacts/contacts";


function Footer() {
    const config = useConfig();
    const [isFormModalOpen, setIsFormModalOpen] = useState(false);

    function toggleModalForm() {
        setIsFormModalOpen(!isFormModalOpen);
    }

    return (
        <footer className="footer">
            {isFormModalOpen && (
                <MainForm
                    title="Записаться на прием"
                    onSubmit={() => reachGoal("appointment-submit", {from: "footer"})}
                    onClose={toggleModalForm}
                />
            )}

            <section className="footer__inner container">
                <div className="footer__section">
                    <Link to="/" aria-label="Главная страница">
                        <LogoSVG className="footer__logo"/>
                    </Link>

                    <div className="footer__company-name">
                        <p>{config.defaultTitle}</p>
                    </div>

                    <Social position={"footer"}/>

                </div>

                <div className="footer__groups footer__section">
                    <div className="footer__group">
                        <h3 className="footer__group-title">Навигация</h3>

                        <ul>
                            {config.navLinks.map(link => (
                                <li key={link.to}>
                                    <Link className="footer__link" to={link.to}>
                                        {link.name}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div className="footer__group">
                        <AddressContactsItem position={"footer"}/>
                        <WorkTimes position="footer"/>
                    </div>
                </div>

                <div className="footer__section">
                    <ul className="footer__contacts">
                        <li className="footer__contacts-item">
                            <a
                                className="footer__phone analytics-mgo-number"
                                href={`tel:${config.primaryPhone}`}
                                onClick={() => reachGoal("phone-click", {from: "footer"})}
                            >
                                {formatPhone(config.primaryPhone)}
                            </a>
                        </li>

                        <li className="footer__contacts-item">
                            <a className="link" href={`mailto:${config.email}`}>
                                {config.email}
                            </a>
                        </li>
                    </ul>

                    <ul className="footer__contacts">
                        <li className="footer__contacts-item">
                            <Button
                                onClick={() => {
                                    reachGoal("appointment-click", {from: "footer"});
                                    toggleModalForm();
                                }}
                                small
                            >
                                записаться на прием
                            </Button>
                        </li>

                        <li className="footer__contacts-item">
                            {config.showCallHome &&
                                <Link className="link"
                                      to="/pages/home-help/"
                                      onClick={() => reachGoal("home-help-click", {from: "footer"})}>
                                    вызвать врача на дом?
                                </Link>}
                        </li>
                    </ul>
                </div>
            </section>

            <div className="container">
                <div className="footer__copyright">
                    <p>
                        © 2020 ООО «Вертеброцентр»
                        <br/>
                        <Link className="link" to="/pages/privacy-policy/">
                            Положение о защите персональных данных
                        </Link>
                    </p>

                    <p>
                        Лицензия Л041-01021-66/00367442 от 23.08.2019
                    </p>
                </div>
                <div align={"center"} style={{marginTop: 15}}>ИМЕЮТСЯ ПРОТИВОПОКАЗАНИЯ, ПРОКОНСУЛЬТИРУЙТЕСЬ С ВРАЧОМ
                </div>
            </div>
        </footer>
    );
}

export default Footer;
