import {ServiceAPI} from "app/api/base";
import Card from "app/components/card";
import Spinner from "app/components/dna";
import Intro from "app/components/intro";
import Layout from "app/components/layout";
import Section from "app/components/section";
import {useSeo} from "app/contexts/seo";
import Benefits from "app/sections/benefits";
import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";


function DiagnosticPage() {
    // const items = data.diags.nodes.map(({id, data}) => ({id, ...data}));
    const [items, setItems] = useState([]);
    const {titles, setSeoName} = useSeo();


    useEffect(() => {
        setSeoName("diagnostic");
        new ServiceAPI().list({category: "diagnostics"}).then(res => {
            console.log(res.data);
            setItems(res.data);
        });
    }, []);

    return <Layout>
        <Helmet>
            {/*<title>Специалисты клиники</title>*/}
            <title>{titles.meta_title}</title>
        </Helmet>
        <Spinner spin={!items?.length}>
            <Section color="gray" curve>
                <Intro title={titles.meta_title}
                       text={""}/>
                <React.Fragment key={"Diags"}>
                    <ul className="card-list container">
                        {items.map(({id, img, name, intro, landing, seo_name}) => (
                            <li className="card-list__item" key={id}>
                                <Card
                                    link={landing?.link || `/diagnostic/${seo_name || id}`}
                                    photo={img}
                                    title={name}
                                    simple={false}
                                >
                                    {intro}
                                </Card>
                            </li>
                        ))}
                    </ul>
                </React.Fragment>
            </Section>
        </Spinner>

        <Benefits/>
    </Layout>;
}


export default DiagnosticPage;
