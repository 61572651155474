import React from 'react';
import FluidImg from 'app/components/fluid-img';
import {ReactComponent as LogoSVG} from 'app/assets/svg/logo/main.svg';
import mainform from 'app/assets/images/main-form2.png'

function Aside() {

  return (
    <aside className="main-form__aside">
      <header className="main-form__aside-header">
        <LogoSVG className="main-form__aside-logo" aria-label="Логотип" />

        <p className="main-form__aside-text">
          В нашей команде собрались настоящие профессионалы
          своего дела, со средним стажем
          <b> более 11 лет.</b>
        </p>
      </header>

      <footer className="main-form__aside-footer">
        <FluidImg
          img={mainform}
          fadeIn={false}
          loading="eager"
          aria-label="Врачи клиники"
        />
      </footer>
    </aside>
  );
}

export default Aside;
