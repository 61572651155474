import React from 'react';
import PropTypes from 'prop-types';
import ArrowControl from 'app/components/arrow-control';
import truncate from 'app/utils/truncate';
import StarMeter from 'app/components/star-meter';
import moment from 'moment';
import './review.scss';

const Review = ({ review, isTruncated, showFullReview }) => {
  const isFullReview = !showFullReview;

  return (
    <div className="review" onClick={showFullReview}>
      <blockquote className="review__content">
        <p className="content__text review__text">
          “
          {isTruncated
            ? truncate(review.text, { limit: 160 })
            : review.text
          }
          ”
        </p>

        {review.team_member && isFullReview && (
          <p className="content__text review__doctor">
            {'Специалист: '}
            {review.team_member.name}
          </p>
        )}

        <div className="review__footer">
          {review.stars && (
            <StarMeter stars={review.stars} />
          )}

          {review.date && (
            <span className="review__date">
              {moment(review.date).format('DD.MM.YYYY')}
            </span>
          )}
        </div>
      </blockquote>

      {showFullReview && (
        <ArrowControl className="review__link" small>
          Читать отзыв полностью
        </ArrowControl>
      )}
    </div>
  );
};

Review.defaultProps = {
  isTruncated: false,
  showFullReview: null,
};

Review.propTypes = {
  review: PropTypes.shape({
    text: PropTypes.string.isRequired,
    doctor: PropTypes.string,
    author: PropTypes.string,
    date: PropTypes.string,
    stars: PropTypes.number,
  }).isRequired,
  isTruncated: PropTypes.bool,
  showFullReview: PropTypes.func,
};

export default Review;
