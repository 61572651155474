import axios from 'axios';
import environment from 'environment';
import Qs from 'qs';

const instance = axios.create({
    baseURL: environment.api,
    paramsSerializer: (params) => {
        return Qs.stringify(params, {arrayFormat: 'repeat'});
    },
});
instance.defaults.headers.common['Content-Type'] = 'application/json';
instance.defaults.headers.common['Accept'] = 'application/json';
instance.defaults.headers.common['Date'] = '';
instance.defaults.timeout = 60000;

instance.interceptors.response.use(r => {
    return r;
}, error => {
    return Promise.reject(error);
});

export default instance;
