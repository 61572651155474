import {useSeo} from "app/contexts/seo";
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Section from 'app/components/section';
import {ReactComponent as IntroSVG} from 'app/assets/svg/intro/methods.svg';
import Intro from 'app/components/intro';
import Method from './method';
import './methods.scss';
import {ServiceAPI} from "app/api/base";

function Methods({hasIcon, ...props}) {
    const [activeMethod, setActiveMethod] = useState(null);
    const [methods, setMethods] = useState([]);
    const {titles} = useSeo();

    React.useEffect(() => {
        new ServiceAPI().list({is_primary: 1, category: ['massage', 'procedures', 'consults']}).then(res => {
            setMethods(res.data);
        })
    }, [])

    function isActiveMethod({id}) {
        return activeMethod === null
            ? id === methods[0].id
            : id === activeMethod.id;
    }

    return (
        <Section id="methods-section" {...props}>
            <Intro title={titles.meta_title}
                   text={"В клинике доступны более 17 технологий лечения. Комбинируя их, мы "
                       + "добиваемся максимального результата за кратчайшие сроки."}
                   icon={hasIcon ? IntroSVG : null}/>

            <div className="container">
                <div className="methods__tabs">
                    <ul className="methods__tabs-list">
                        {methods.map(method => (
                            <li className="methods__tabs-item" key={method.id}>
                                <button
                                    className={
                                        classNames(
                                            'methods__tabs-button',
                                            {'is-active': isActiveMethod(method)},
                                        )
                                    }
                                    type="button"
                                    onClick={() => setActiveMethod(method)}
                                >
                                    {method.name}
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>

                {methods.map(method => (
                    <Method
                        method={method}
                        isActive={isActiveMethod(method)}
                        key={method.seo_name || method.id}
                    />
                ))}
            </div>
        </Section>
    );
}

Methods.defaultProps = {
    hasIcon: true
};

Methods.propTypes = {
    hasIcon: PropTypes.bool
};

export default Methods;
