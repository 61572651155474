import React from 'react';
import Helmet from 'react-helmet';
import Layout from 'app/components/layout';
import Section from 'app/components/section';
import Intro from 'app/components/intro';

const NotFoundPage = () => (
  <Layout>
    <Helmet>
      <title>Страница не найдена</title>
    </Helmet>

    <Section>
      <Intro
        title="Страница не найдена"
        text="404 Ошибка"
      />

      <div className="error container">
        <p className="content__text">
          Проверьте, правильно ли Вы ввели адрес. Правильно? Тогда, возможно,
          на сайте идут технические работы или адрес страницы был изменен.
        </p>

        <p className="content__text">
          Обновите страницу или перейдите на
          {' '}
          <a className="link" href="/">Главную</a>
          .
        </p>
      </div>
    </Section>
  </Layout>
);

export default NotFoundPage;
