import React, {createContext, useContext, useEffect} from 'react';
import PropTypes from 'prop-types';
import internalConfig from 'app/config';
import {ConfigAPI} from "app/api/base";
import {useLocalStorage} from "react-use";

const LIFETIME = 600000;

const ConfigContext = createContext();

function useConfig() {
    const context = useContext(ConfigContext);

    if (!context)
        throw new Error('useConfig must be used within ConfigProvider');

    return context;
}

function ConfigProvider({children}) {
    const [config, setConfig, removeConfig] = useLocalStorage('config', internalConfig);

    useEffect(() => {
        new ConfigAPI().is_updated();

        if (!config?.author || config?.timestamp + LIFETIME < Date.now()) {
            new ConfigAPI().retrieve().then(r => {
                const cfg = {...internalConfig, ...r.data, 'timestamp': Date.now()};
                setConfig(cfg)
            });
        }
    }, []);

    return (
        <ConfigContext.Provider value={config}>
            {children}
        </ConfigContext.Provider>
    );
}

ConfigProvider.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};

export {ConfigProvider, useConfig};
