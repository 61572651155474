import {Link} from "app/components/link";
import React, {useEffect, useState} from "react";
import Helmet from "react-helmet";
import {TeamAPI} from "app/api/base";
import Spinner from "app/components/dna";
import Intro from "app/components/intro";
import Layout from "app/components/layout";
import Member from "app/components/member";
import Section from "app/components/section";
import {useConfig} from "app/contexts/config";
import {useSeo} from "app/contexts/seo";
import Benefits from "app/sections/benefits";


function GroupTitle({children, landing}) {
    if (landing) {
        return <Link to={landing}>
            <h3 className="price__title">{children}</h3>
        </Link>;
    }
    else {
        return <h3 className="price__title">{children}</h3>;
    }
}

function PhysGroups() {
    const [members, setMembers] = useState([]);
    const {groups} = useConfig();
    const [mbrMap, setMbrMap] = useState([]);
    const [mbrNoGrp, setMbrNoGrp] = useState([]);

    useEffect(() => {
        new TeamAPI().list({show: 1}).then(res => {
            setMembers(res.data);
        });
    }, []);

    useEffect(() => {
        // if (groups && (groups?.length > 0)) {
        //     setMbrMap(groups.map(([g, gn]) => [gn, members.filter(m => m?.teamgroup == g)]).filter(([gn, mmbrs]) => mmbrs.length > 0));
        //     setMbrNoGrp(members.filter(m => m?.teamgroup == null));
        // }
        if (groups && (groups?.length > 0)) {
            setMbrMap(groups.map(grp => [grp, members.filter(m => m?.group == grp.code)])
                            .filter(([grp, mmbrs]) => mmbrs.length > 0));
            setMbrNoGrp(members.filter(m => m?.group == null));
        }
    }, [members, groups]);

    // return <Spinner spin={false}>
    return <Spinner spin={!((mbrMap.length > 0) || mbrNoGrp.length > 0)}>
        {mbrMap.map(([grp, mmbrs]) => (
            <p>
                <GroupTitle landing={grp?.landing?.link}>{grp.name}</GroupTitle>
                <ul className="card-list card-list--wide container">
                    {mmbrs.map(member => (
                        <li className="card-list__item" key={member.id}>
                            <Member member={member}/>
                        </li>
                    ))}
                </ul>
            </p>
        ))}
        {(mbrNoGrp.length > 0) &&
            <p>
                {(mbrMap.length > 0) && <Intro title={"Другие специалисты"}/>}
                <ul className="card-list card-list--wide container">
                    {mbrNoGrp.map(member => (
                        <li className="card-list__item" key={member.id}>
                            <Member member={member}/>
                        </li>
                    ))}
                </ul>
            </p>}
    </Spinner>;
}


function PhysiciansPage() {

    const {titles, setSeoName} = useSeo();

    setSeoName("physicians");

    return (
        <Layout>
            <Helmet>
                {/*<title>Специалисты клиники</title>*/}
                <title>{titles.meta_title || titles.title}</title>
                {/*<meta name="description" content={titles.meta_description || titles.title} data-react-helmet="true"/>*/}
                <meta name="keywords" content={titles.meta_keywords || titles.title} data-react-helmet="true"/>
            </Helmet>

            <Section color="gray" curve>
                <Intro
                    title={titles.title}
                    text="В нашей команде собрались настоящие профессионалы своего дела, со средним стажем более 11 лет."
                />

                <PhysGroups/>
            </Section>

            <Benefits/>
        </Layout>
    );
}

export default PhysiciansPage;
