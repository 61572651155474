import React, {useState} from 'react';
import PropTypes from 'prop-types';
// import format from 'date-fns/format';
import './calendar.scss';
import moment from 'moment';

function Time({time, onClick, toggle}) {
    function Click() {
        if (typeof onClick === 'function') {
            onClick(time);
        }
    }

    return (
      <div
        className={`calendar-time ${(toggle) ? 'calendar-time__toggle' : ''}`}
        onClick={Click}
      >
          {(() => {
              if (time instanceof String || typeof time === 'string') return moment(new Date(time)).format('HH:mm');
              if (time instanceof Date) return moment(time).format('HH:mm');

              return 'No val';
          })()}
      </div>
    );
}

Time.propTypes = {
    time: PropTypes.any.isRequired,
    onClick: PropTypes.func.isRequired,
    toggle: PropTypes.bool.isRequired,
};

function Times({times, onChoose}) {
    const [tme, setTme] = useState(null);

    function Click(time) {
        setTme(time);
        if (typeof onChoose === 'function') onChoose(time);
    }

    return (
      <div className={'calendar-times'}>
          {times.map(t => (<Time time={t} onClick={Click} toggle={t === tme}/>))}
      </div>
    );
}

Times.propTypes = {
    times: PropTypes.array.isRequired,
    onChoose: PropTypes.func.isRequired,
};

export {
    Times,
};
